import { Typography, Box, List, ListItem, Divider, Grid, TextField, Checkbox, Button, AlertTitle, Alert, CircularProgress } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import "../../../../src/mainseller.scss";
import { Link, useNavigate } from 'react-router-dom'
import { useMutation } from '@apollo/client';
import * as mutations from "../../../Operations/Mutations";
import { CountryContext } from '../../..';
import { useTranslation } from 'react-i18next';

const Login = () => {
    const [showPassword, setShowPassword] = useState(false)
    const [login, setLogin] = useState({ email: "", password: "" })
    const [resError, setResError] = useState("");
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const { t }: { t: (key: string) => string } = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

      const {store} = useContext(CountryContext);

    let [generateCustomerToken] = useMutation(
        mutations.loginMutation.LOGIN_MUTATION,
        {
            context: {
                headers: {
                    Connection: "keep-alive",
                    store: store,
                },
            },
        }
    );

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setLogin(prevLogin => ({
            ...prevLogin, [name]: value
        }))
    }

    const handleGenerateToken = async (event: any) => {
        event.preventDefault();
        setIsLoading(true);
        const data = login;
        try {
            const response = await generateCustomerToken({
                variables: data,
            });
            const generateCustData = response?.data;
            localStorage.setItem(
                "loggedInCust",
                JSON.stringify(generateCustData?.generateCustomerToken?.token)
            );
            const isLogin = {
                isLogin: true,
                token: generateCustData?.generateCustomerToken?.token,
            };
            setResError("");
            setShowErrorAlert(false);
            // setLogin({ email: "", password: "" });
            navigate(`/dashboard`, { state: isLogin });
        } catch (error: any) {
            setIsLoading(false);
            if (error) {
                setResError(t("Home.login.login_err"));
                setShowErrorAlert(true);
            }
            setLogin({ email: "", password: "" });
        }
    };

    const handleForgotPwdClick = () => {
        navigate("/forgot-password");
    };


    return (
        <>
            <div className='login-main directionrtl'>
                <div className='seller-acc-main'>
                    <Typography variant='h1'>{t("Home.login.seller_login")}</Typography>
                    {showErrorAlert && (
                      <Alert severity="error">
                        <AlertTitle>Error</AlertTitle>
                        {resError}
                      </Alert>
                    )}
                    <form onSubmit={handleGenerateToken}>
                        <Box sx={{
                            border: "1px solid #DEDCDC",
                            backgroundColor: "#FFFFFF"
                        }} className="main-box">
                            <List>
                                <ListItem>
                                    <Typography className='title'>
                                    {t("Home.login.registerd_seller")}
                                    </Typography>
                                </ListItem>
                                <Divider />
                                <Grid className='company-upper' container spacing={2} sx={{ padding: '30px' }}>
                                    <Grid item xs={12} md={12} lg={12}>
                                        <label>{t("Home.login.email")}</label>
                                        <TextField
                                            name='email'
                                            variant="outlined"
                                            className='input-field'
                                            type='email'
                                            value={login.email}
                                            onChange={handleChange}
                                            autoComplete='on'
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12}>
                                        <label>{t("Home.login.Password")}</label>
                                        <TextField
                                            name='password'
                                            variant="outlined"
                                            className='input-field'
                                            type={showPassword ? 'text' : 'password'}
                                            value={login.password}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sx={{ maxWidth: '100% !important' }}>
                                        <ListItem sx={{ padding: '0px' }}>
                                            <Checkbox
                                                checked={showPassword}
                                                onChange={() => setShowPassword(!showPassword)}
                                            />
                                            <Typography className='checkbox-text'>{t("Home.login.show_pwd")}</Typography>
                                        </ListItem>
                                        <ListItem sx={{ padding: '0px' }}>
                                            <Typography className='checkbox-text forgotPwdText' onClick={handleForgotPwdClick}>{t("Home.login.forget_pwd")}</Typography>
                                        </ListItem>
                                    </Grid>
                                </Grid>
                            </List>
                        </Box>
                        <Box sx={{ width: '100%', marginTop: '30px' }}>
                            <Button className='create-btn' type='submit'>
                            {isLoading ? (
                  <CircularProgress size={32} color="inherit" />
                ) : (
                  t("Home.login.log_in") 
                )}  
                            </Button>
                        </Box>
                    </form>
                </div>
                <div className='seller-acc-main'>
                    <Typography variant='h1'>{t("Home.login.new_sellers")}</Typography>
                    {/* company */}
                    <Box sx={{
                        border: "1px solid #DEDCDC",
                        backgroundColor: "#FFFFFF"
                    }} className="main-box" maxWidth={'712px'}>
                        <List>
                            <Grid className='company-upper' container spacing={2} sx={{ padding: '30px' }}>
                                <Grid className='company-upper' container spacing={2} sx={{ padding: '30px' }}>
                                    <Grid item xs={12} sx={{ maxWidth: '100% !important' }}>
                                        <label>{t("Home.login.desc")}</label>
                                    </Grid>

                                </Grid>
                            </Grid>
                        </List>
                    </Box>
                    <Box sx={{ width: '100%', marginTop: '30px', display:'flex', justifyContent:'center' }}>
                        <Button className='create-btn' sx={{maxWidth:'712px'}}>
                            <Link to="/register" style={{ color: "#fff", textDecoration: "none" }}>
                            {t("Home.login.create_an_acc")}
                            </Link>
                        </Button>
                    </Box>
                </div>
            </div>
        </>
    )
}

export default Login