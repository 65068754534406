import React from 'react';
import './NoProductFound.scss';
import { useTranslation } from 'react-i18next';

const NoProductFound = () => {
  const { t }: { t: (key: string) => string } = useTranslation();

  return (
    <div className="no-product-found">
      <h1 className="message">{t("Home_Dashboard.noProductFound.title")}</h1>
      <p className="description">
      {t("Home_Dashboard.noProductFound.desc")}
      </p>
    </div>
  );
};

export default NoProductFound;
