import React, { createContext, useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import './i18n';
import axios from 'axios';
import i18n from './i18n';
import { toast } from 'react-toastify';

const client = new ApolloClient({
  uri: "https://access.jebelz.com/graphql",
  // uri: "https://jebelz.com/graphql",
  // uri: "https://jebelzm2.ezmartech.com/graphql",
  // uri: "https://jebelzstg.ezmartech.com/graphql",
  cache: new InMemoryCache(),
});

export const CountryContext = createContext({
  store: "",
  setStore: (store: string) => {},
  lang: "",
  setLang: (lang: string) => {},
  toggleLanguage: () => {},
});

const CountryProvider = ({ children }: any) => {
  const [store, setStore] = useState("");
  const [lang, setLang] = useState("");

  useEffect(() => {
    const host = window.location.hostname;
    let storeLang = "";
    if (host.includes("seller-in")) {
      setStore("sell_in");
    } else if (host.includes("seller-sa")) {
      setStore("sell_sa");
    } else if (host.includes("seller-om")) {
      setStore("sell_om");
    }else if (host.includes("seller")) {
      setStore("seller");
    } else {
      axios
        .get("https://ipapi.co/json/")
        .then((response) => {
          let data = response.data;
          if (data?.country_code === "UA" || data?.country_code === "AE") {
            storeLang = "seller";
          } else if (data?.country_code === "SD" ||
            data?.country_code === "SAU" ||
            data?.country_code === "SA") {
            storeLang = "sell_sa";
          } else if (data?.country_code === "OM" ||
            data?.country_code === "OMN" ||
            data?.country_code === "OM") {
            storeLang = "sell_om";
          } else if ( data?.country_code === "IN" ||
            data?.country_code === "IND") {
            storeLang = "sell_in";
          } else {
            storeLang = "seller";
          }
          setStore(storeLang);
        })
        .catch((error) => {
          console.error("Error fetching geo information:", error);
        });
    }
  }, []); 

  useEffect(() => {
    const savedLanguage = localStorage.getItem('preferredLanguage');
    if (savedLanguage) {
      setLang(savedLanguage);
      i18n.changeLanguage(savedLanguage);
      document.documentElement.setAttribute("lang", savedLanguage);
      document.body.classList.add(savedLanguage);
      document.body.classList.remove(savedLanguage === "en" ? "ar" : "en");
      if (savedLanguage === "ar") {
        document.body.classList.add("directionrtl");
      } else {
        document.body.classList.remove("directionrtl");
      }
    }
  }, []);
  
  useEffect(() => {
    if (store && store !== "") {
      const savedLanguage = localStorage.getItem('preferredLanguage');
      if (!savedLanguage) {
        if (store === "seller" || store === "sell_in") {
          setLang("en");
          i18n.changeLanguage("en");
          document.documentElement.setAttribute("lang", "en");
          document.body.classList.add("en");
          document.body.classList.remove("ar");
        } else if (store === "sell_sa" || store === "sell_om") {
          setLang("ar");
          i18n.changeLanguage("ar");
          document.documentElement.setAttribute("lang", "ar");
          document.body.classList.add("ar");
          document.body.classList.remove("en");
        } else {
          setLang("en");
          i18n.changeLanguage("en");
          document.documentElement.setAttribute("lang", "en");
          document.body.classList.add("en");
          document.body.classList.remove("ar");
        }
      }
    }
  }, [store]);

  const toggleLanguage = () => {
    const newLang = lang === "en" ? "ar" : "en";
    i18n.changeLanguage(newLang);
    document.documentElement.setAttribute("lang", newLang);
    document.body.classList.add(newLang);
    document.body.classList.remove(newLang === "en" ? "ar" : "en");
    if (newLang === "ar") {
      document.body.classList.add("directionrtl");
    } else {
      document.body.classList.remove("directionrtl");
    }
    setLang(newLang);
    localStorage.setItem('preferredLanguage', newLang); 
  };
  

  return (
    <CountryContext.Provider value={{ store, setStore, lang, setLang, toggleLanguage }}>
      {children}
    </CountryContext.Provider>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <BrowserRouter>
        <CountryProvider>
          <App />
        </CountryProvider>
      </BrowserRouter>
    </ApolloProvider>
  </React.StrictMode>
);

reportWebVitals();
