import { Box, Divider, Grid } from "@mui/material";
import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";

const Home = () => {
  const { t }: { t: (key: string) => string } = useTranslation();

  return (
    <div className="directionrtl">
      <Box className="benefit-section directionrtl">
        <div className="main-head">
          <div className="title">
            <Divider className="title-line" />
            <h1>{t("Home.main.benefits")}</h1>
            <Divider className="title-line" />
          </div>
        </div>
        <Grid container spacing={2} className="benefit-grid">
          <Grid item xs={6} sm={6} md={4} lg={3}>
            <Card sx={{ borderRadius: "0" }} className="benefit-card">
              <CardActionArea>
                <CardMedia
                  component="img"
                  src={require("../../../assets/images/card-1.png")}
                  alt="card"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5">
                  {t("Home.main.reach_to_million_desc")}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={3}>
            <Card sx={{ borderRadius: "0" }} className="benefit-card">
              <CardActionArea>
                <CardMedia
                  component="img"
                  src={require("../../../assets/images/card_img-2.png")}
                  alt="card"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5">
                  {t("Home.main.secure_timely_desc")}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={3}>
            <Card sx={{ borderRadius: "0" }} className="benefit-card">
              <CardActionArea>
                <CardMedia
                  component="img"
                  src={require("../../../assets/images/card_img-3.png")}
                  alt="card"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5">
                  {t("Home.main.fast_stress_desc")}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={3}>
            <Card sx={{ borderRadius: "0" }} className="benefit-card">
              <CardActionArea>
                <CardMedia
                  component="img"
                  src={require("../../../assets/images/card_img-4.png")}
                  alt="card"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5">
                  {t("Home.main.free_easy_desc")}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={3}>
            <Card sx={{ borderRadius: "0" }} className="benefit-card">
              <CardActionArea>
                <CardMedia
                  component="img"
                  src={require("../../../assets/images/card_img-5.png")}
                  alt="card"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5">
                  {t("Home.main.pay_only")}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={3}>
            <Card sx={{ borderRadius: "0" }} className="benefit-card">
              <CardActionArea>
                <CardMedia
                  component="img"
                  src={require("../../../assets/images/card_img-6.png")}
                  alt="card"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5">
                  {t("Home.main.training")}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <Box className="selling-section">
        <div>
          <div className="selling-text-section">
            <h1>{t("Home.main.sell_on_jebelz")}</h1>
          </div>
          <div className="checkbox-wrapper">
            <div className="checkbox-text">
              <CheckBoxOutlinedIcon style={{ color: "white" }} />
              <h5>{t("Home.main.trade_license")} </h5>
            </div>
            <div className="checkbox-text">
              <CheckBoxOutlinedIcon style={{ color: "white" }} />
              <h5>{t("Home.main.vat_certificate")}</h5>
            </div>
            <div className="checkbox-text">
              <CheckBoxOutlinedIcon style={{ color: "white" }} />
              <h5>{t("Home.main.bank_acc")}</h5>
            </div>
            <div className="checkbox-text">
              <CheckBoxOutlinedIcon style={{ color: "white" }} />
              <h5>{t("Home.main.1_product")}</h5>
            </div>
          </div>
        </div>
      </Box>
      <Box className="bottom-section">
        <div className="text-section">
          <h2>{t("Home.main.how_it_works")}</h2>
          <h5>{t("Home.main.how_it_works_desc")}</h5>
        </div>
        <div className="image-section">
          <img
            src={i18n.language === "ar" ? require("../../../assets/images/arabicBanner.png") : require("../../../assets/images/home_bottom.png")}
            alt="how it works"
          />
        </div>
      </Box>
    </div>
  );
};

export default Home;
