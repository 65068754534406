import "../../../../src/mainseller.scss";
import { Divider } from '@mui/material'
import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#10CDF2",
    color: "#000000",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
  width: "50%",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function createData(name: string, percentage: string) {
  return { name, percentage };
}

interface Row {
  name: string;
  percentage: string;
}
interface CustomizedTablesProps {
  rows: Row[];
}

export function CustomizedTables({ rows }: CustomizedTablesProps) {
  const { t }: { t: (key: string) => string } = useTranslation();

  return (
    <TableContainer component={Paper} className="directionrtl">
      <Table sx={{ minWidth: 400, border: '1px solid #E5E4E4 ', borderRadius: '0' }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell className="table-title-name">{t("Home.referral_fee.category")}</StyledTableCell>
            <StyledTableCell className="table-title-name">{t("Home.referral_fee.referral")}</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map((row:any) => (
            <StyledTableRow key={row.name}>
              <StyledTableCell className="table-content-name" component="th" scope="row">
                {row.name}
              </StyledTableCell>
              <StyledTableCell className="table-content-name" align="right">{row.percentage}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}


const ReferralFee = () => {
  const { t }: { t: (key: string) => string } = useTranslation();

  const rows = [
    createData(t("Home.referral_fee.coffee_machines"), "14%"),
    createData(t("Home.referral_fee.coffee_accessories"), "14%"),
    createData(t("Home.referral_fee.coffee_consumables"), "14%"),
    // createData("Coffee Machines", "14%"),
    createData(t("Home.referral_fee.beverage_equipments"), "14%"),
    createData(t("Home.referral_fee.dish_washing_equipments"), "14%"),
    createData(t("Home.referral_fee.ice_equipments"), "14%"),
    createData(t("Home.referral_fee.refrigeration"), "14%"),
    createData(t("Home.referral_fee.commercial_oven"), "14%"),
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='referral-main center-div' >
      <div className='main-head' >
        <div className='title'>
          <Divider className='title-line'/>
          <h1>{t("Home.referral_fee.title")}</h1>
          <Divider className='title-line'/>
        </div>
      </div>
      <div className='bottom-section bottom-section-referral'>
        <CustomizedTables rows={rows}/>
      </div>
    </div>
  )
}

export default ReferralFee

