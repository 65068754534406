import React, { ReactNode, useEffect, useState } from 'react'
import './main.scss';
import Sidebar from '../../Components/New_Sidebar/NewSidebar';
import Topbar from '../../Components/New_Topbar/NewTopbar';

interface MainProps {
    children: ReactNode;
  }

const Main: React.FC<MainProps> = ({ children }) => {

    const title = window.location.pathname.split("/");
    const selectedItem = title[title.length - 1].charAt(0).toUpperCase() + title[title.length - 1].slice(1)
    const [selectedMenuItem, setSelectedMenuItem] = useState(selectedItem);

    //eslint-disable-next-line
    const handleMenuItem = (menuItem: any) => {
        setSelectedMenuItem(menuItem);
        localStorage.setItem('selectedMenuItem', menuItem);
    };

    useEffect(() => {
        console.log("selectedMenuItem", selectedMenuItem)
    }, [selectedMenuItem])

    return (
        <>
            <div className='main main_block directionrtl'>
                <div className='main-sidebar sidebar_section'>
                    <Sidebar />
                </div>
                <div className="main-content main_section directionrtl">
                    <div className='top-bar header'>
                        <Topbar />
                    </div>
                    <div className='page-details dashboard_main_content directionrtl'>
                        {children}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Main
