import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Pagination,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useQuery } from "@apollo/client";
import OrdersTable from "../../../../Components/Dashboard_Table/OrdersTable";
import { OrdersPageQuery } from "../../../../Operations/Queries";
import { CountryContext } from "../../../..";
import Toaster from "../../../../Components/ToastMessage";
import MobileTable from "../mobileTable/MobileTable";
import NoProductFound from "../../../../Components/Noproduct/NoProductFound";
import { useTranslation } from "react-i18next";

// Define Props type
interface ShipedOrderProps {
  menuItems: string[];
  selectedMenuItem: string;
  tabchange: boolean;
  setErrorMessage: any;
}

// Define Order type
interface ShipedOrderData {
  id: string;
  order: string;
  date: string;
  itemName: string;
  itemPrice: number;
  quantity: number;
  itemTotal: number;
}

const ShipedOrder: React.FC<ShipedOrderProps> = ({
  menuItems,
  selectedMenuItem,
  tabchange,
  setErrorMessage,
}) => {
  const [shippedOrders, setShippedOrders] = useState<ShipedOrderData[]>([]);
  const [pageSize, setPageSize] = useState<number>(20);
  const [currentPage, setCurrentPage] = useState({
    pending: 1,
    confirmed: 1,
    awb: 1,
    shipped: 1,
    delivered: 1,
    cancelled: 1,
    archived: 1,
  });
  const [search, setSearch] = useState({
    pending: "",
    confirmed: "",
    awb: "",
    shipped: "",
    delivered: "",
    cancelled: "",
    archived: "",
  });
  const loggedInToken = localStorage.getItem("loggedInCust");
  const { t }: { t: (key: string) => string } = useTranslation();
  const { store } = useContext(CountryContext);

  const array: any = [
    { lable: "10", value: 10 },
    { lable: "20", value: 20 },
    { lable: "25", value: 25 },
  ];
 

  const {
    data: OrdersListShippedData,
    refetch: refetchShippedOrders,
    loading: deliverOrderLoading,
    error: shipedOrderError,
  } = useQuery(OrdersPageQuery.ORDERS_SHIPPED, {
    context: {
      headers: {
        Authorization: loggedInToken
          ? `Bearer ${JSON.parse(loggedInToken)}`
          : "",
        Connection: "keep-alive",
        store: store,
      },
    },
    variables: {
      orderNumber: search.shipped,
      pageSize: pageSize,
      currentPage: currentPage.shipped,
    },
  });

  if (shipedOrderError) {
    setErrorMessage(shipedOrderError);
  }
  // const totalItems =
  // OrdersListShippedData?.sellerOrdersList
  // ?.[0]?.items?.length || 0;
  // const totalPages = Math.ceil(totalItems / pageSize);

  const ordersShippedData = OrdersListShippedData?.sellerOrdersList[0]?.items;
  const ordersShippedPageInfo =
    OrdersListShippedData?.sellerOrdersList[0]?.pageInfo;

  useEffect(() => {
    const transformed = ordersShippedData?.map((order: any, index: any) => ({
      id: order.item_id,
      order: order.order_number,
      waybillNumber: order.waybill_no,
      waybillFile: order.waybill_file,
      date: order.date,
      itemName: order.product_name,
      itemPrice: order.price,
      quantity: order.qty,
      itemTotal: order.sub_total,
    }));
    setShippedOrders(transformed);
  }, [ordersShippedData]);

  const handlePageChange = (_: any, value: number) => {
    setCurrentPage({ ...currentPage, pending: value });
    refetchShippedOrders({
      orderNumber: search.shipped,
      pageSize: 20,
      currentPage: value,
    });
  };

  const handlePageSizeChange = (event: any) => {
    const newSize = event.target.value as number;
    setPageSize(newSize);
    setCurrentPage({ ...currentPage, shipped: 1 });
    refetchShippedOrders({
      orderNumber: search.shipped,
      pageSize: newSize,
      currentPage: 1,
    });
  };

  const handleSearch = () => {
    setCurrentPage({ ...currentPage, shipped: 1 });
    refetchShippedOrders({
      orderNumber: search.shipped,
      pageSize: 20,
      currentPage: 1,
    });
  };

  useEffect(() => {
    refetchShippedOrders();
    //eslint-disable-next-line
  }, [tabchange]);

  const headCellsForShipped = [
    { id: "order", numeric: false, disablePadding: false, label: t("Home_Dashboard.orders.order") },
    {
      id: "waybillNumber",
      numeric: false,
      disablePadding: false,
      label: t("Home_Dashboard.orders.waybillNumber"),
    },
    { id: "date", numeric: false, disablePadding: false, label: t("Home_Dashboard.orders.date") },
    {
      id: "itemName",
      numeric: false,
      disablePadding: false,
      label: t("Home_Dashboard.orders.itemName"),
    },
    {
      id: "itemPrice",
      numeric: true,
      disablePadding: false,
      label: t("Home_Dashboard.orders.itemPrice"),
    },
    { id: "quantity", numeric: true, disablePadding: false, label: t("Home_Dashboard.orders.quantity") },
    {
      id: "itemTotal",
      numeric: true,
      disablePadding: false,
      label: t("Home_Dashboard.orders.itemTotal"),
    },
  ];

  return (
    <>
      <Toaster />
      <div className="main">
        <div
          className="btn-group"
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            marginBottom: "10px",
          }}
        >
          <TextField
            placeholder={t("Home_Dashboard.orders.search_by")}
            name="search_pending_orders"
            id="search_product"
            value={search.shipped}
            onChange={(e) => setSearch({ ...search, shipped: e.target.value })}
            variant="outlined"
            className="search-text-field"
          />
          <Button
            onClick={handleSearch}
            className="search-button"
          >
            <Typography className="btn-text">{t("Home_Dashboard.orders.search")}</Typography>
          </Button>
        </div>
        {shipedOrderError ? (
          <p className="error-text">{shipedOrderError?.message}</p>
        ) : deliverOrderLoading ? (
          <CircularProgress />
        ) : shippedOrders && shippedOrders?.length > 0 ? (
          <OrdersTable
            headCells={headCellsForShipped}
            rows={shippedOrders}
            pageChange={handlePageChange}
            pageType="shipped"
            pageNumber={ordersShippedPageInfo?.current_page}
          />
        ) : (
          <NoProductFound />
        )}

        {shippedOrders && shippedOrders?.length > 0 && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              pt: 2,
            }}
            className="deskPagination"
          >
            <Stack direction="row" spacing={2} alignItems="center">
              <Typography variant="body2">{t("Home_Dashboard.orders.item_per_page")}:</Typography>
              <Select
                value={pageSize}
                onChange={handlePageSizeChange}
                variant="outlined"
                size="small"
              >
                {array?.map((size: any) => (
                  <MenuItem key={size} value={size.value}>
                    {size.lable}
                  </MenuItem>
                ))}
              </Select>
            </Stack>
            <Pagination
              count={Math.ceil(80 / pageSize)}
              shape="rounded"
              page={currentPage.shipped}
              onChange={handlePageChange}
            />
          </Box>
        )}
      </div>
      {menuItems.map(
        (menuItem, index) =>
          selectedMenuItem === menuItem && (
            <div className="mobile-wrapper" key={index}>
              <MobileTable
                headCells={headCellsForShipped}
                rows={shippedOrders}
                onChange={handlePageChange}
                onRowsPerPageChange={handlePageSizeChange}
                pageSize={pageSize}
                pageIs={currentPage.shipped}
              />
            </div>
          )
      )}
    </>
  );
};

export default ShipedOrder;
