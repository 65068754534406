import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Pagination,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useMutation, useQuery } from "@apollo/client";
import OrdersTable from "../../../../Components/Dashboard_Table/OrdersTable";
import { toast } from "react-toastify";
import { OrdersPageQuery } from "../../../../Operations/Queries";
import { CountryContext } from "../../../..";
import { ordersActionMutation } from "../../../../Operations/Mutations";
import Toaster from "../../../../Components/ToastMessage";
import MobileTable from "../mobileTable/MobileTable";
import NoProductFound from "../../../../Components/Noproduct/NoProductFound";
import { useTranslation } from "react-i18next";

// Define Props type
interface PendingOrderProps {
  menuItems: string[];
  selectedMenuItem: string;
  tabchange: boolean;
  setErrorMessage: any;
}

// Define Order type
interface Order {
  id: string;
  order: string;
  date: string;
  itemName: string;
  itemPrice: number;
  quantity: number;
  itemTotal: number;
}

const PendingOrder: React.FC<PendingOrderProps> = ({
  menuItems,
  selectedMenuItem,
  tabchange,
  setErrorMessage,
}) => {
  const [pendingOrders, setPendingOrders] = useState<Order[]>([]);
  const [ordersPendingPageInfo, setOrdersPendingPageInfo] = useState<any>();
  const [pageSize, setPageSize] = useState<number>(20);
  const [currentPage, setCurrentPage] = useState({
    pending: 1,
    confirmed: 1,
    awb: 1,
    shipped: 1,
    delivered: 1,
    cancelled: 1,
    archived: 1,
  });

  const loggedInToken = localStorage.getItem("loggedInCust");
  const [search, setSearch] = useState({
    pending: "",
    confirmed: "",
    awb: "",
    shipped: "",
    delivered: "",
    cancelled: "",
    archived: "",
  });
  const { store } = useContext(CountryContext);
  const { t }: { t: (key: string) => string } = useTranslation();

  const array: any = [
    { lable: "10", value: 10 },
    { lable: "20", value: 20 },
    { lable: "25", value: 25 },
  ];

  const [confirmOrders] = useMutation(ordersActionMutation.ORDER_CONFIRM, {
    context: {
      headers: {
        Authorization: loggedInToken
          ? `Bearer ${JSON.parse(loggedInToken)}`
          : "",
        Connection: "keep-alive",
        store: store,
      },
    },
  });

  const [cancelOrders] = useMutation(ordersActionMutation.ORDER_CANCEL, {
    context: {
      headers: {
        Authorization: loggedInToken
          ? `Bearer ${JSON.parse(loggedInToken)}`
          : "",
        Connection: "keep-alive",
        store: store,
      },
    },
  });

  const {
    data: OrdersListPendingData,
    loading: loadingPendingOrders,
    refetch: refetchPendingOrders,
    error: PendingOrderError,
  } = useQuery(OrdersPageQuery.ORDERS_PENDING, {
    context: {
      headers: {
        Authorization: loggedInToken
          ? `Bearer ${JSON.parse(loggedInToken)}`
          : "",
        Connection: "keep-alive",
        store: store,
      },
    },
    variables: {
      orderNumber: search.pending,
      pageSize: pageSize,
      currentPage: currentPage.pending,
    },
  });

  if (PendingOrderError) {
    setErrorMessage(PendingOrderError);
  }

  const additionalActionsForPending = [
    {
      label: t("Home_Dashboard.orders.cnf_btn"),
      buttonLabel: t("Home_Dashboard.orders.cnf_btn"),
      onClick: async (row: any, cont: any) => {
        toast.info(t("Home_Dashboard.orders.processing_req_toast"), {
          position: toast.POSITION.TOP_RIGHT,
        });
        const itemId = row.id;
        const orderNumber = row.order;
        const finalPayload = {
          action: "orderAction",
          status: "accepted",
          order_number: orderNumber,
          item_id: itemId,
        };
        try {
          //eslint-disable-next-line
          const response = await confirmOrders({
            variables: finalPayload,
          });
          toast.success("Order confirmed", {
            position: toast.POSITION.TOP_RIGHT,
          });
          // alert(`Order Confirmed for ${orderNumber}`)
          refetchPendingOrders();
        } catch (error) {
          toast.error(t("Home_Dashboard.orders.confirmed_error_toast"), {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      },
    },
    {
      label: t("Home_Dashboard.orders.canc_btn"),
      buttonLabel: t("Home_Dashboard.orders.canc_btn"),
      onClick: async (row: any) => {
        toast.info(t("Home_Dashboard.orders.processing_req_toast"), {
          position: toast.POSITION.TOP_RIGHT,
        });
        const itemId = row.id;
        const orderNumber = row.order;
        const finalPayload = {
          action: "orderAction",
          status: "canceled",
          order_number: orderNumber,
          item_id: itemId,
        };
        try {
          //eslint-disable-next-line
          const response = await cancelOrders({
            variables: finalPayload,
          });
          toast.success("Order cancelled", {
            position: toast.POSITION.TOP_RIGHT,
          });
          // alert(`Order Cancelled for ${orderNumber}`)
          refetchPendingOrders();
        } catch (error) {
          toast.error(t("Home_Dashboard.orders.cancle_error_toast"), {
            position: toast.POSITION.TOP_RIGHT,
          });
          // alert("An error occurred while confirming the order.")
        }
      },
    },
  ];

  useEffect(() => {
    const ordersPendingData =
      OrdersListPendingData?.sellerOrdersList?.[0]?.items;
    //  const pageInfo = OrdersListPendingData?. sellerOrdersList[0]?.pageInfo;
    setOrdersPendingPageInfo(
      OrdersListPendingData?.sellerOrdersList?.[0]?.pageInfo
    );

    const transformed = ordersPendingData?.map((order: any) => ({
      id: order.item_id,
      order: order.order_number,
      date: order.date,
      itemName: order.product_name,
      itemPrice: order.price,
      quantity: order.qty,
      itemTotal: order.sub_total,
    }));
    setPendingOrders(transformed || []);
  }, [OrdersListPendingData]);

  const handlePageChange = (_: any, value: number) => {
    setCurrentPage({ ...currentPage, pending: value });
    refetchPendingOrders({
      orderNumber: search.pending,
      pageSize: 20,
      currentPage: value,
    });
  };

  const handlePageSizeChange = (event: any) => {
    const newSize = event.target.value as number;
    setPageSize(newSize);
    setCurrentPage({ ...currentPage, pending: 1 });
    refetchPendingOrders({
      orderNumber: search.pending,
      pageSize: newSize,
      currentPage: 1,
    });
  };

  const handleSearch = () => {
    setCurrentPage({ ...currentPage, pending: 1 });
    refetchPendingOrders({
      orderNumber: search.pending,
      pageSize: 20,
      currentPage: 1,
    });
  };

  useEffect(() => {
    refetchPendingOrders();
    //eslint-disable-next-line
  }, [tabchange]);

  const headCellsForPending = [
    { id: "order", numeric: false, disablePadding: false, label: t("Home_Dashboard.orders.order") },
    { id: "date", numeric: false, disablePadding: false, label: t("Home_Dashboard.orders.date") },
    {
      id: "itemName",
      numeric: false,
      disablePadding: false,
      label: t("Home_Dashboard.orders.itemName"),
    },
    {
      id: "itemPrice",
      numeric: true,
      disablePadding: false,
      label: t("Home_Dashboard.orders.itemPrice"),
    },
    { id: "quantity", numeric: true, disablePadding: false, label: t("Home_Dashboard.orders.quantity") },
    {
      id: "itemTotal",
      numeric: true,
      disablePadding: false,
      label: t("Home_Dashboard.orders.itemTotal"),
    },
  ];

  return (
    <>
      <Toaster />
      <div className="main">
        <div
          className="btn-group"
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            marginBottom: "10px",
          }}
        >
          <TextField
            placeholder={t("Home_Dashboard.orders.search_by")}
            name="search_product"
            id="search_product"
            value={search.pending}
            onChange={(e) => setSearch({ ...search, pending: e.target.value })}
            variant="outlined"
            className="search-text-field"
          />
          <Button
            onClick={handleSearch}
            className="search-button"
          >
            <Typography className="btn-text">{t("Home_Dashboard.orders.search")}</Typography>
          </Button>
        </div>
        {PendingOrderError ? (
          <p className="error-text">{PendingOrderError?.message}</p>
        ) : loadingPendingOrders ? (
          <CircularProgress />
        ) : pendingOrders && pendingOrders?.length > 0 ? (
          <OrdersTable
            headCells={headCellsForPending}
            rows={pendingOrders}
            ButtonActions={additionalActionsForPending}
            pageChange={handlePageChange}
            pageType="pending"
            pageNumber={ordersPendingPageInfo?.current_page}
          />
        ) : (
          <NoProductFound />
        )}

        {pendingOrders && pendingOrders?.length > 0 && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              pt: 2,
            }}
            className="deskPagination"
          >
            <Stack direction="row" spacing={2} alignItems="center">
              <Typography variant="body2">{t("Home_Dashboard.orders.item_per_page")} : </Typography>
              <Select
                value={pageSize}
                onChange={handlePageSizeChange}
                variant="outlined"
                size="small"
              >
                {array?.map((size: any) => (
                  <MenuItem key={size} value={size.value}>
                    {size.lable}
                  </MenuItem>
                ))}
              </Select>
            </Stack>
            <Pagination
              count={Math.ceil(80 / pageSize)}
              shape="rounded"
              page={currentPage.pending}
              onChange={handlePageChange}
            />
          </Box>
        )}
      </div>
      {menuItems.map(
        (menuItem, index) =>
          selectedMenuItem === menuItem && (
            <div className="mobile-wrapper" key={index}>
              <MobileTable
                headCells={headCellsForPending}
                rows={pendingOrders}
                ButtonActions={additionalActionsForPending}
                onChange={handlePageChange}
                onRowsPerPageChange={handlePageSizeChange}
                pageSize={pageSize}
                pageIs={currentPage.pending}
              />
            </div>
          )
      )}
    </>
  );
};

export default PendingOrder;
