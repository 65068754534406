import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material'
import "./Settings.scss"
import React, { useContext} from 'react'
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { settingsPageQuery } from '../../../Operations/Queries';
import { CountryContext } from '../../..';
import { useTranslation } from 'react-i18next';

const Settings = () => {
  const navigate = useNavigate();
  const loggedInToken = localStorage.getItem("loggedInCust");
  const { t }: { t: (key: string) => string } = useTranslation();
  const {store} = useContext(CountryContext);

  const { data: SettingsMainData, error: settingsError, loading: settingsLoading } = useQuery(settingsPageQuery.SETTINGS_MAINPAGE, {
    context: {
      headers: {
        Authorization: loggedInToken
          ? `Bearer ${JSON.parse(loggedInToken)}`
          : "",
        Connection: "keep-alive",
        store: store,
      },
      onerror(error: any) {
        console.error("error 11: ",error);
      }
    },
  });

  const settingsData = SettingsMainData?.sellerData;

  // const ErrorSettingsPage = "Session expired. Please logout and try to login again.";
  const ErrorSettingsPage = settingsError?.message;

  const handleEditName = () => {
    navigate('/dashboard/settings/edit-name');
  };

  const handleEditEmail = () => {
    navigate('/dashboard/settings/edit-email');
  };

  const handleChangePassword = () => {
    navigate('/dashboard/settings/change-password');
  };

  const handleEditBankDetails = () => {
    navigate('/dashboard/settings/edit-bank-details');
  };

  const handleEditPickUp = () => {
    navigate('/dashboard/settings/edit-pickup-details');
  };

  return (
    <div className='settings'>
      {
        settingsError ? (
          <p className='error-text'>{ErrorSettingsPage}</p>
        ) : (
          settingsLoading ? (
            <CircularProgress className='loader' />
          ) : (
            <>
              <Box sx={{ width: '100%' }} className="main-container">
                <Grid container spacing={2} className='grid-wrapper'>
                  <Grid item xs={12} sm={12} md={12} className='title'>
                    <Typography variant='h5'>{t("Home_Dashboard.settings.title")}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={8} className='data-container'>
                    <div className='data-wrapper'>
                      <div className='data'>
                        <Typography variant='h5'>{t("Home_Dashboard.settings.name")}:</Typography>
                        <Typography variant='h6'>{`${settingsData?.companyInformation[0]?.firstname} ${settingsData?.companyInformation[0]?.lastname}`}</Typography>
                      </div>
                      <div className='btn-head'>
                        <Button className='btn' onClick={handleEditName}><Typography variant='h6'>{t("Home_Dashboard.settings.edit")}</Typography></Button>
                      </div>
                    </div>
                    <div className='data-wrapper'>
                      <div className='data'>
                        <Typography variant='h5'>{t("Home_Dashboard.settings.email_id")}:</Typography>
                        <Typography variant='h6'>{settingsData?.companyInformation[0]?.email}</Typography>
                      </div>
                      <div className='btn-head'>
                        <Button className='btn' onClick={handleEditEmail}><Typography variant='h6'>{t("Home_Dashboard.settings.edit")}</Typography></Button>
                      </div>
                    </div>
                    <div className='data-wrapper'>
                      <div className='data'>
                        <Typography variant='h5'>{t("Home_Dashboard.settings.password")}:</Typography>
                        <Typography variant='h6'>**************</Typography>
                      </div>
                      <div className='btn-head'>
                        <Button className='btn' onClick={handleChangePassword}><Typography variant='h6'>{t("Home_Dashboard.settings.edit")}</Typography></Button>
                      </div>
                    </div>
                    <div className='data-wrapper'>
                      <div className='data'>
                        <Typography variant='h5'>{t("Home_Dashboard.settings.bank_acc")}:</Typography>
                        <Typography variant='h6'>{settingsData?.bankDetails[0]?.bank_account_number}</Typography>
                      </div>
                      <div className='btn-head'>
                        <Button className='btn' onClick={handleEditBankDetails}><Typography variant='h6'>{t("Home_Dashboard.settings.edit")}</Typography></Button>
                      </div>
                    </div>
                    <div className='data-wrapper'>
                      <div className='data'>
                        <Typography variant='h5'>{t("Home_Dashboard.settings.pick_up")}:</Typography>
                        <Typography variant='h6'>{settingsData?.pickupLocation[0]?.vendor_address}</Typography>
                      </div>
                      <div className='btn-head'>
                        <Button className='btn' onClick={handleEditPickUp}><Typography variant='h6'>{t("Home_Dashboard.settings.edit")}</Typography></Button>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Box>
            </>
          )
        )
      }
    </div>
  )
}

export default Settings