import { useMutation } from '@apollo/client';
import { Box, Button, Divider, Grid, TextField, Typography } from '@mui/material'
import React, { useContext, useState } from 'react'
import { settingsMutation } from '../../../Operations/Mutations';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Toaster from '../../../Components/ToastMessage';
import { CountryContext } from '../../..';
import { useTranslation } from 'react-i18next';

const ChangePassword = () => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const { t }: { t: (key: string) => string } = useTranslation();
  const loggedInToken = localStorage.getItem("loggedInCust");
  const navigate = useNavigate()

  const {store} = useContext(CountryContext);

  const [updatePassword] = useMutation(
    settingsMutation.UPDATE_PASSWORD_MUTATION,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store,
        },
      },
    }
  );


  const saveNewPassword = async () => {
    try {
      if (currentPassword === newPassword) {
        toast.error(t("Home_Dashboard.editPwd.new_pass_err"), {
          position: toast.POSITION.TOP_RIGHT
      });
        return;
      }
      if (newPassword !== confirmPassword) {
        toast.error(t("Home_Dashboard.editPwd.pwd_not_match_err"), {
          position: toast.POSITION.TOP_RIGHT
      });
        return;
      }
      await updatePassword({
        variables: {
          currentPassword: currentPassword,
          newPassword: newPassword
        }
      });
      toast.success(t("Home_Dashboard.editPwd.success_pwd"), {
        position: toast.POSITION.TOP_RIGHT
    });
    } catch (error) {
      toast.error(t("Home_Dashboard.editPwd.err_pwd"), {
        position: toast.POSITION.TOP_RIGHT
    });
    }
  };

  const backToSettings = () => {
    navigate("/dashboard/settings")
  }

  return (
    <Box>
      <Toaster/>
      <Grid container spacing={2} className="change-name change-password">
        <Grid item xs={12} sm={12} md={12} className='settings-header'>
          <Typography variant='h5'>{t("Home_Dashboard.editPwd.title")}</Typography>
        </Grid>
        <Divider className='divider' />
        <Grid item xs={12} sm={12} md={12} className='title text-title'>
          <Typography variant='h6'>{t("Home_Dashboard.editPwd.desc")}</Typography>
        </Grid>
        <Grid container spacing={2} xs={12} sm={12} md={12} lg={9} className='form-details'>
          <Grid item xs={12} sm={12} md={8} className='name'>
            <label>{t("Home_Dashboard.editPwd.current_pwd")} :</label>
            <TextField
              variant="outlined"
              className='input-field'
              name='currentPassword'
              placeholder={t("Home_Dashboard.editPwd.current_pwd")}
              onChange={(e) => setCurrentPassword(e.target.value)}
            />
            <label>{t("Home_Dashboard.editPwd.new_pwd")}:</label>
            <TextField
              variant="outlined"
              className='input-field'
              name='newPassword'
              placeholder={t("Home_Dashboard.editPwd.new_pwd")}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <label>{t("Home_Dashboard.editPwd.cnf_new_pwd")}:</label>
            <TextField
              variant="outlined"
              type='password'
              className='input-field'
              name='confirmPassword'
              placeholder={t("Home_Dashboard.editPwd.cnf_new_pwd")}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} className='btn-grid'>
          <Button className='btn btn-back' onClick={backToSettings}><Typography variant='h6'>{t("Home_Dashboard.editPwd.back")}</Typography></Button>
          <Button className='btn' onClick={saveNewPassword}><Typography variant='h6'>{t("Home_Dashboard.editPwd.save_changes")}</Typography></Button>
        </Grid>
      </Grid>
    </Box>
  )
}

export default ChangePassword