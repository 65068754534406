import { useMutation } from "@apollo/client";
import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { settingsMutation } from "../../../Operations/Mutations";
import { toast } from "react-toastify";
import Toaster from "../../../Components/ToastMessage";
import { useNavigate } from "react-router-dom";
import { CountryContext } from "../../..";
import { useTranslation } from "react-i18next";

const EditPickUpDetails = () => {
  const [formDataforPickUp, setFormDataforPickUp] = useState({
    country: "",
    streetAddress: "",
    city: "",
    state: "",
    pinCode: "",
    googleMapLocation: "",
  });
  const loggedInToken = localStorage.getItem("loggedInCust");
  const navigate = useNavigate();
  const { t }: { t: (key: string) => string } = useTranslation();
  const { store } = useContext(CountryContext);

  const [updatePickUpData] = useMutation(
    settingsMutation.UPDATE_PICKUP_MUTATION,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store,
        },
      },
    }
  );

  const Countries = [
    t("Home_Dashboard.pickUpDetails.oman"),
    "UAE",
    t("Home_Dashboard.pickUpDetails.india"),
    t("Home_Dashboard.pickUpDetails.saudi"),
  ];

  const handleCountryChange = (event: SelectChangeEvent) => {
    setFormDataforPickUp({ ...formDataforPickUp, country: event.target.value });
  };

  const handlePickUpFormData = (event: any) => {
    const { name, value } = event.target;
    setFormDataforPickUp({ ...formDataforPickUp, [name]: value });
  };

  const formforAPI = {
    city: formDataforPickUp.city,
    region: formDataforPickUp.state,
    postcode: formDataforPickUp.pinCode,
    vendor_address: formDataforPickUp.streetAddress,
    country: formDataforPickUp.country,
    google_map: formDataforPickUp.googleMapLocation,
  };

  const savePickUpFormData = async () => {
    try {
      await updatePickUpData({
        variables: {
          input: {
            pickupLocation: [formforAPI],
          },
        },
      });
      toast.success(t("Home_Dashboard.pickUpDetails.success_toast"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      toast.error(t("Home_Dashboard.pickUpDetails.err_toast"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const backToSettings = () => {
    navigate("/dashboard/settings");
  };

  return (
    <Box>
      <Grid container spacing={2} className="change-name pick-up-location">
        <Toaster />
        <Grid item xs={12} sm={12} md={12} className="settings-header">
          <Typography variant="h5">
            {t("Home_Dashboard.pickUpDetails.title")}
          </Typography>
        </Grid>
        <Divider className="divider" />
        <Grid item xs={12} sm={12} md={12} className="title text-title">
          <Typography variant="h6">
            {t("Home_Dashboard.pickUpDetails.desc")}
          </Typography>
        </Grid>
        <Grid
          container
          spacing={2}
          xs={12}
          sm={12}
          md={12}
          lg={9}
          className="form-details"
        >
          <Grid item xs={12} sm={12} md={12} className="name">
            <label>{t("Home_Dashboard.pickUpDetails.country")}</label>
            <FormControl className="select-form">
              <InputLabel id="demo-simple-select-label">
                {t("Home_Dashboard.pickUpDetails.country")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={formDataforPickUp.country}
                label={t("Home_Dashboard.pickUpDetails.country")}
                onChange={handleCountryChange}
                dir="rtl" 
              >
                {Countries?.map((item: any, index: any) => (
                  <MenuItem
                    key={index}
                    value={item}
                    className="menuitem-select"
                    style={{ textAlign: "right" }}
                  >
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={12} className="name">
            <label>{t("Home_Dashboard.pickUpDetails.street_add")}</label>
            <TextField
              variant="outlined"
              className="input-field"
              name="streetAddress"
              value={formDataforPickUp.streetAddress}
              onChange={handlePickUpFormData}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} className="name">
            <label>{t("Home_Dashboard.pickUpDetails.town/city")}</label>
            <TextField
              variant="outlined"
              className="input-field"
              name="city"
              value={formDataforPickUp.city}
              onChange={handlePickUpFormData}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} className="name">
            <label>{t("Home_Dashboard.pickUpDetails.region")}</label>
            <TextField
              variant="outlined"
              className="input-field"
              name="state"
              value={formDataforPickUp.state}
              onChange={handlePickUpFormData}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} className="name">
            <label>{t("Home_Dashboard.pickUpDetails.post_code")}</label>
            <TextField
              variant="outlined"
              className="input-field"
              name="pinCode"
              value={formDataforPickUp.pinCode}
              onChange={handlePickUpFormData}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} className="name">
            <label>{t("Home_Dashboard.pickUpDetails.gmap_location")}</label>
            <TextField
              variant="outlined"
              className="input-field"
              name="googleMapLocation"
              value={formDataforPickUp.googleMapLocation}
              onChange={handlePickUpFormData}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} className="btn-grid">
          <Button className="btn btn-back" onClick={backToSettings}>
            <Typography variant="h6">
              {t("Home_Dashboard.pickUpDetails.back")}
            </Typography>
          </Button>
          <Button className="btn" onClick={savePickUpFormData}>
            <Typography variant="h6">
              {t("Home_Dashboard.pickUpDetails.save_changes")}
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default EditPickUpDetails;
