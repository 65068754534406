import { Box, Grid, Typography, Divider, TextField, Button } from '@mui/material'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CountryContext } from '../../..';
import { useQuery } from '@apollo/client';
import { settingsPageQuery } from '../../../Operations/Queries';

const EditEmail = () => {
    const [editedEmail, setEditedEmail] = useState("");
    const [emailError, setEmailError] = useState(false);
    const navigate = useNavigate();
    const loggedInToken = localStorage.getItem("loggedInCust");
  const { t }: { t: (key: string) => string } = useTranslation();
  const {store} = useContext(CountryContext);

    const { data: SettingsMainData } = useQuery(settingsPageQuery.SETTINGS_MAINPAGE, {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store,
        },
        onerror(error: any) {
          console.error("error 11: ",error);
        }
      },
    });
  
    const settingsData = SettingsMainData?.sellerData;

    const validateEmail = (email: any) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
      };

    const saveEditedEmail = () => {
        const isEmailValid = validateEmail(editedEmail);
        if (isEmailValid) {
          setEmailError(false);
        } else {
          setEmailError(true);
        }
      }

      const backToSettings = () => {
        navigate("/dashboard/settings")
      }

  return (
    <Box sx={{ width: '100%' }}>
        <Grid container spacing={2} className="change-name change-email">
                <Grid item xs={12} sm={12} md={12} className='settings-header'>
                  <Typography variant='h5'>{t("Home_Dashboard.editEmail.title")}</Typography>
                </Grid>
                <Divider className='divider' />
                <Grid container spacing={2} xs={12} sm={12} md={12} lg={9} className='form-details'>
                  <Grid item xs={12} sm={12} md={12} className='title current-email'>
                    <Typography variant='h6'>{t("Home_Dashboard.editEmail.current_email")}:<span>{settingsData?.companyInformation?.[0]?.email}
                   </span></Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} className='title text-title'>
                    <Typography variant='h6'>Enter {t("Home_Dashboard.editEmail.desc_1")} <span>({t("Home_Dashboard.editEmail.otp")})</span> {t("Home_Dashboard.editEmail.desc_2")}.</Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} className='name'>
                    <label>{t("Home_Dashboard.editEmail.new_email")}:</label>
                    <TextField
                      variant="outlined"
                      className='input-field'
                      name='newEmail'
                      placeholder={t("Home_Dashboard.editEmail.new_email")}
                      value={editedEmail}
                      onChange={(e) => setEditedEmail(e.target.value)}
                    />
                    {emailError && <Typography variant='caption' color='error'>{t("Home_Dashboard.editEmail.email_err")}</Typography>}
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={8} className='btn-grid'>
                  <Button className='btn btn-back' onClick={backToSettings}><Typography variant='h6'>{t("Home_Dashboard.editEmail.back")}</Typography></Button>
                  <Button className='btn' onClick={saveEditedEmail}><Typography variant='h6'>{t("Home_Dashboard.editEmail.coninue")}</Typography></Button>
                </Grid>
              </Grid>
    </Box>
  )
}

export default EditEmail