import { useContext } from "react";
import { CountryContext } from "../..";

const CountryFlag = () => {
    const {store} = useContext(CountryContext);
    //const st = store.toString();
    //UAE Store: seller
    //KSA Store: sell_sa
    //Oman Store: sell_om
    //India Store: sell_in
    // const flags = {seller: "inflag.png", sell_sa: "ksaflag.png", sell_om: "omflag.png", sell_in: "inflag.png"};
    return(
        <>
            {
                (store==="seller") ?
                //eslint-disable-next-line
                <img src={`${window.location.origin}/aeflag.png`} height={35} style={{width: "47px",
                    height: "30px"}} />
                : (store==="sell_sa") ? 
                //eslint-disable-next-line
                <img src={`${window.location.origin}/ksaflag.png`} height={35} style={{width: "47px",
                    height: "30px"}} />
                : (store==="sell_om") ? 
                //eslint-disable-next-line
                <img src={`${window.location.origin}/omflag.png`} height={35}style={{width: "47px",
                    height: "30px"}} />
                : (store==="sell_in") ?
                //eslint-disable-next-line 
                <img src={`${window.location.origin}/inflag.png`} height={35} style={{width: "47px",
                    height: "30px"}} />
                : ''  
            }
        </>
    )
}

export default CountryFlag;