import React, { useContext, useEffect, useState } from "react";
import "./HomeDashboard.scss";
import {
  Box,
  CircularProgress,
  Grid,
  Link,
  Modal,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import Chart from "react-google-charts";
import * as queries from "../../../Operations/Queries";
import { useMutation, useQuery } from "@apollo/client";
import { CountryContext } from "../../..";
import { useNavigate } from "react-router-dom";
import * as mutations from "../../../Operations/Mutations";
import { useTranslation } from "react-i18next";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#10CDF2" : "#F2F0F0",
  },
}));

const HomeDashboard = () => {
  const [duration, setDuration] = useState("Yearly");
  //eslint-disable-next-line
  const [transformedData, setTransformedData] = useState<any[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [allProducts, setAllProducts] = useState([]);
  const navigate = useNavigate();
  const { t }: { t: (key: string) => string } = useTranslation();
  
  //eslint-disable-next-line
  const timePeriod = ["Yearly", "quarterly", "monthly", "weekly", "daily"];
  const loggedInToken = localStorage.getItem("loggedInCust");

  //eslint-disable-next-line
  const handleChange = (event: SelectChangeEvent) => {
    setDuration(event.target.value as string);
  };

  useEffect(() => {
    console.log("selected", duration);
  }, [duration]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { store } = useContext(CountryContext);

  const {
    loading: dashboardLoading,
    error: dashboardError,
    data: dashboardData,
  } = useQuery(queries.HomeDashboardQuery.DASHBOARD_QUERY, {
    context: {
      headers: {
        Authorization: loggedInToken
          ? `Bearer ${JSON.parse(loggedInToken)}`
          : "",
        Connection: "keep-alive",
        store: store,
      },
    },
  });

  const homeDashboardData = dashboardData?.dashboard?.[0];

  if (homeDashboardData) {
    localStorage.setItem("totalCount", homeDashboardData?.pending_orders_count);
  }

  const [revokeCustomertoken] = useMutation(
    mutations.logoutMutation.REVOKE_CUSTOMER_TOKEN,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store,
        },
      },
    }
  );
  const handleLogOut = async () => {
    try {
      await revokeCustomertoken();
      localStorage.removeItem("selectedMenuItem");
      localStorage.removeItem("preferredLanguage");
      navigate("/");
    } catch (error) {
      console.error("Error revoking token:", error);
      localStorage.removeItem("selectedMenuItem");
      localStorage.removeItem("preferredLanguage");
      navigate("/");
    }
  };
  const ErrorHomeDashboard = dashboardError?.message;
  if (ErrorHomeDashboard === "Seller is not loggedin") {
    handleLogOut();
  }

  // Define the headCells for the table
  //eslint-disable-next-line
  const headCells = [
    { id: "order", numeric: false, disablePadding: false, label: "Order" },
    { id: "date", numeric: false, disablePadding: false, label: "Date" },
    {
      id: "itemName",
      numeric: false,
      disablePadding: false,
      label: "Item Name",
    },
    {
      id: "itemPrice",
      numeric: true,
      disablePadding: false,
      label: "Item Price (AED)",
    },
    { id: "quantity", numeric: true, disablePadding: false, label: "Quantity" },
    {
      id: "itemTotal",
      numeric: false,
      disablePadding: false,
      label: "Item Total (AED)",
    },
  ];

  useEffect(() => {
    const transformed = homeDashboardData?.latest_orders.map(
      (order: any, index: any) => ({
        id: index,
        order: order.increment_id,
        date: order.created_at,
        itemName: order.name,
        itemPrice: order.price,
        quantity: order.qty,
        itemTotal: order.sub_total,
      })
    );
    setTransformedData(transformed);
  }, [homeDashboardData?.latest_orders]);

  const chartData = homeDashboardData?.top_selling_category;
  const chartTitle = homeDashboardData?.top_selling_category[0]?.__typename;

  const mapData = chartData?.map((item: any) => [item.name, item.count]);
  mapData?.unshift(["Category", "count"]);

  const mapTitle = {
    title: chartTitle,
  };

  const handleSeeAll = () => {
    setAllProducts(homeDashboardData?.top_products || []);
    setShowModal(true);
  };

  return (
    <>
      {dashboardError ? (
        <p className="error-text">{ErrorHomeDashboard}</p>
      ) : dashboardLoading ? (
        <CircularProgress className="loader" />
      ) : (
        <>
          <div className="home-dashboard directionrtl">
            <Box>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={8}>
                  <div className="top-card-wrapper">
                    <Box>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={6}>
                          <div className="orders-card card-bg">
                            <div className="top">
                              <Typography variant="h5">{t("Home_Dashboard.dashboard.order")} ({t("Home_Dashboard.dashboard.s")})</Typography>
                              <Typography variant="h6">
                                {homeDashboardData?.total_orders_count}
                              </Typography>
                            </div>
                            <div className="bottom">
                              <div className="progress-with-text">
                                <div className="bottom-text">
                                  <Typography variant="h3">{t("Home_Dashboard.dashboard.pending")}</Typography>
                                  <Typography variant="h3">
                                    {homeDashboardData?.pending_orders_count}
                                  </Typography>
                                </div>
                                <BorderLinearProgress
                                  variant="determinate"
                                  value={40}
                                  sx={{ width: "100%", height: "5px" }}
                                />
                              </div>
                              <div className="progress-with-text">
                                <div className="bottom-text">
                                  <Typography variant="h3">
                                  {t("Home_Dashboard.dashboard.processing")}
                                  </Typography>
                                  <Typography variant="h3">
                                    {homeDashboardData?.processing_orders_count}
                                  </Typography>
                                </div>
                                <BorderLinearProgress
                                  variant="determinate"
                                  value={70}
                                  sx={{ width: "100%", height: "5px" }}
                                />
                              </div>
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                          <div className="orders-card card-bg">
                            <div className="top">
                              <Typography variant="h5">{t("Home_Dashboard.dashboard.product")} ({t("Home_Dashboard.dashboard.s")})</Typography>
                              <Typography variant="h6">
                                {homeDashboardData?.top_products?.length}
                              </Typography>
                            </div>
                            <div className="bottom">
                              <div className="top-selling-list">
                                <Typography variant="h3">
                                {t("Home_Dashboard.dashboard.top_selling_product")}
                                </Typography>
                                <Typography variant="h3" className="seeAllBtn">
                                  <Link underline="none" onClick={handleSeeAll}>
                                  {t("Home_Dashboard.dashboard.see_all")}
                                  </Link>
                                </Typography>
                              </div>
                              {/* {
                                                                        homeDashboardData?.top_products.map((item: any, index: any) => (
                                                                            <div className='top-selling-list top-selling-list-item' key={index}>
                                                                                <Typography variant='h4'>{item.name}</Typography>
                                                                                <Typography variant='h4'>{item.count}</Typography>
                                                                            </div>
                                                                        ))
                                                                    } */}
                              {homeDashboardData?.top_products
                                .slice(0, 3)
                                .map((item: any, index: any) => (
                                  <div
                                    className="top-selling-list top-selling-list-item"
                                    key={index}
                                  >
                                    <Typography variant="h4">
                                      {item.name}
                                    </Typography>
                                    <Typography variant="h4">
                                      {item.count}
                                    </Typography>
                                  </div>
                                ))}
                              <Modal
                                className="modal-main"
                                open={showModal}
                                onClose={() => setShowModal(false)}
                              >
                                <div className="modal-content">
                                  <div className="modal-header">
                                    <Typography variant="h3">
                                    {t("Home_Dashboard.dashboard.top_selling_product")}
                                    </Typography>
                                  </div>
                                  {allProducts.map((item: any, index: any) => (
                                    <div
                                      className="top-selling-list top-selling-list-item modal-list"
                                      key={index}
                                    >
                                      <Typography
                                        variant="h4"
                                        className="list-name"
                                      >
                                        {item.name}
                                      </Typography>
                                      <Typography
                                        variant="h4"
                                        className="list-count"
                                      >
                                        {item.count}
                                      </Typography>
                                    </div>
                                  ))}
                                </div>
                              </Modal>
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </Box>
                  </div>
                  {/* <div className='top-card-wrapper graph-wrapper'>
                                                <Box>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={12} sm={12} md={6}>
                                                            <div>
                                                                <div className='orders-card card-bg'>
                                                                    <div className='graph-label'>
                                                                        <Typography>Lifetime Sales {homeDashboardData?.currency} {homeDashboardData?.lifetime_sales}</Typography>
                                                                    </div>
                                                                    <div className='right-side'>
                                                                        <Typography>Total Payout <span>AED 0.00</span></Typography>
                                                                        <FormControl className='select-form'>
                                                                            <Select
                                                                                labelId="demo-simple-select-label"
                                                                                id="demo-simple-select"
                                                                                value={duration}
                                                                                label="Yearly"
                                                                                onChange={handleChange}
                                                                            >
                                                                                {timePeriod.map((item, index) => (
                                                                                    <MenuItem key={index} value={item} className='menuitem-select'>
                                                                                        {item}
                                                                                    </MenuItem>
                                                                                ))}
                                                                            </Select>
                                                                        </FormControl>
                                                                    </div>
                                                                </div>
                                                                <div className='bottom-map-block'>
                                                                    <img src={require("../../../assets/images/dashboard_map.png")} alt='map-img' />
                                                                </div>
                                                            </div>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={6}>
                                                            <div className='order-status-card card-bg'>
                                                                <div className='header-section'>abc</div>
                                                                <div className='order-status-section scrollbar-design'>
                                                                    <div className='status-wrapper'>
                                                                        <div className='svg-icon'>
                                                                            <ArrowUpwardIcon />
                                                                        </div>
                                                                        <div className='status-text'>
                                                                            <Typography className='status-title-text'>Order #ORD-AE-0006198 is processing</Typography>
                                                                            <Typography className='time-text'>4 hours ago</Typography>
                                                                        </div>
                                                                    </div>
                                                                    <div className='status-wrapper'>
                                                                        <div className='svg-icon'>
                                                                            <ArrowUpwardIcon />
                                                                        </div>
                                                                        <div className='status-text'>
                                                                            <Typography className='status-title-text'>Order #ORD-AE-0006198 is processing</Typography>
                                                                            <Typography className='time-text'>4 hours ago</Typography>
                                                                        </div>
                                                                    </div>
                                                                    <div className='status-wrapper'>
                                                                        <div className='svg-icon'>
                                                                            <ArrowUpwardIcon />
                                                                        </div>
                                                                        <div className='status-text'>
                                                                            <Typography className='status-title-text'>Order #ORD-AE-0006198 is processing</Typography>
                                                                            <Typography className='time-text'>4 hours ago</Typography>
                                                                        </div>
                                                                    </div>
                                                                    <div className='status-wrapper'>
                                                                        <div className='svg-icon'>
                                                                            <ArrowUpwardIcon />
                                                                        </div>
                                                                        <div className='status-text'>
                                                                            <Typography className='status-title-text'>Order #ORD-AE-0006198 is processing</Typography>
                                                                            <Typography className='time-text'>4 hours ago</Typography>
                                                                        </div>
                                                                    </div>
                                                                    <div className='status-wrapper'>
                                                                        <div className='svg-icon'>
                                                                            <ArrowUpwardIcon />
                                                                        </div>
                                                                        <div className='status-text'>
                                                                            <Typography className='status-title-text'>Order #ORD-AE-0006198 is processing</Typography>
                                                                            <Typography className='time-text'>4 hours ago</Typography>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </div> */}
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <div className="top-card-wrapper right-side-maps-block">
                    <div className="orders-card card-bg">
                      <Chart
                        chartType="PieChart"
                        data={mapData}
                        options={mapTitle}
                        width={"100%"}
                        height={"400px"}
                        className="chart"
                      />
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Box>
            {/*<div className='latest-order-card-table'>
                                    <Box>
                                        <Typography className='table-title' variant='h4'>Latest Orders</Typography>
                                    </Box>
                                    <Box className="desktop-table-block">
                                        <DashBoardTable headCells={headCells} rows={transformedData} />
                                    </Box>

                                    <Box className="mobile-table-block" sx={{ mt: 2, border: "1px solid #E4E3E3", backgroundColor: "#FAF9F9" }}>
                                        <MobileTable headCells={headCells} rows={transformedData} />
                                    </Box>
                                </div>*/}
          </div>
        </>
      )}
    </>
  );
};

export default HomeDashboard;
