import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Pagination,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useMutation, useQuery } from "@apollo/client";
import OrdersTable from "../../../../Components/Dashboard_Table/OrdersTable";
import { toast } from "react-toastify";
import { OrdersPageQuery } from "../../../../Operations/Queries";
import { CountryContext } from "../../../..";
import { ordersActionMutation } from "../../../../Operations/Mutations";
import Toaster from "../../../../Components/ToastMessage";
import MobileTable from "../mobileTable/MobileTable";
import NoProductFound from "../../../../Components/Noproduct/NoProductFound";
import { useTranslation } from "react-i18next";

interface ConfirmedOrderProps {
  menuItems: string[];
  selectedMenuItem: string;
  tabchange: boolean;
  setErrorMessage: any;
}

interface ConfirmedOrderData {
  id: string;
  order: string;
  date: string;
  itemName: string;
  itemPrice: number;
  quantity: number;
  itemTotal: number;
}

const ConfirmedOrder: React.FC<ConfirmedOrderProps> = ({
  menuItems,
  selectedMenuItem,
  tabchange,
  setErrorMessage,
}) => {
  const [confirmedOrders, setConfirmedOrders] = useState<ConfirmedOrderData[]>(
    []
  );
  // const [ordersConfirmedData, setOrdersConfirmedData] = useState<any>();
  const [ordersConfirmedPageInfo, setOrdersConfirmedPageInfo] = useState<any>();
  const [pageSize, setPageSize] = useState<number>(20);
  const [currentPage, setCurrentPage] = useState({
    pending: 1,
    confirmed: 1,
    awb: 1,
    shipped: 1,
    delivered: 1,
    cancelled: 1,
    archived: 1,
  });

  const loggedInToken = localStorage.getItem("loggedInCust");
  const [search, setSearch] = useState({
    pending: "",
    confirmed: "",
    awb: "",
    shipped: "",
    delivered: "",
    cancelled: "",
    archived: "",
  });
  const { t }: { t: (key: string) => string } = useTranslation();

  const array: any = [
    { lable: "10", value: 10 },
    { lable: "20", value: 20 },
    { lable: "30", value: 30 },
  ];
  const { store } = useContext(CountryContext);

  const [generateAwb] = useMutation(ordersActionMutation.ORDER_GENERATE_AWB, {
    context: {
      headers: {
        Authorization: loggedInToken
          ? `Bearer ${JSON.parse(loggedInToken)}`
          : "",
        Connection: "keep-alive",
        store: store,
      },
    },
  });

  const {
    data: OrdersListConfirmedData,
    refetch: refetchConfirmedOrders,
    loading: loadingConfirmedOrder,
    error: confirmedOrderError,
  } = useQuery(OrdersPageQuery.ORDERS_CONFIRMED, {
    context: {
      headers: {
        Authorization: loggedInToken
          ? `Bearer ${JSON.parse(loggedInToken)}`
          : "",
        Connection: "keep-alive",
        store: store,
      },
    },
    variables: {
      orderNumber: search.confirmed,
      pageSize: pageSize,
      currentPage: currentPage.confirmed,
    },
  });

  if (confirmedOrderError) {
    setErrorMessage(confirmedOrderError);
  }

  // const totalItems =
  // OrdersListConfirmedData?.sellerOrdersList
  // ?.[0]?.items?.length || 0;
  // const totalPages = Math.ceil(totalItems / pageSize);
  // if (confirmedOrderError) {
  //   toast.error("seller is not logged in");
  //   localStorage.removeItem("selectedMenuItem");
  //   localStorage.removeItem("loggedInCust");
  //   navigate("/");
  // }

  const additionalActionsForConfirmed = [
    {
      label: t("Home_Dashboard.orders.awd_btn"),
      buttonLabel: t("Home_Dashboard.orders.awd_btn"),
      onClick: async (row: any) => {
        toast.info("Please wait while processing your request!", {
          position: toast.POSITION.TOP_RIGHT,
        });
        // const rowId = row.id;
        const itemId = row.id;
        const orderNumber = row.order;
        const finalPayload = {
          action: "orderAction",
          status: "generate_awb",
          order_number: orderNumber,
          item_id: itemId,
        };
        try {
          const response = await generateAwb({
            variables: finalPayload,
          });
          if (
            response?.data?.sellerOrderAction?.order_item_status ===
            "awb_generated"
          ) {
            window.open(response?.data?.sellerOrderAction?.file);
          }
          if (response?.data?.sellerOrderAction == null) {
            toast.warning(
              t("Home_Dashboard.orders.awb_warning"),
              {
                position: toast.POSITION.TOP_RIGHT,
              }
            );
          } else {
            toast.success(t("Home_Dashboard.orders.awb_success"), {
              position: toast.POSITION.TOP_RIGHT,
            });
          }

          // alert(`Order Confirmed for ${orderNumber}`)
          refetchConfirmedOrders();
          //  refetchAwbOrders();
        } catch (error) {
          toast.error(t("Home_Dashboard.orders.awb_err"), {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      },
    },
  ];

  useEffect(() => {
    // setOrdersConfirmedData(OrdersListConfirmedData?.sellerOrdersList?.[0]?.items);
    setOrdersConfirmedPageInfo(
      OrdersListConfirmedData?.sellerOrdersList?.[0]?.pageInfo
    );
    const transformed =
      OrdersListConfirmedData?.sellerOrdersList?.[0]?.items?.map(
        (order: any, index: any) => ({
          id: order?.item_id,
          order: order?.order_number,
          date: order?.date,
          itemName: order?.product_name,
          itemPrice: order?.price,
          quantity: order?.qty,
          itemTotal: order?.sub_total,
          waybillNumber: order?.waybill_no,
          waybillFile: order?.waybill_file,
        })
      );
    setConfirmedOrders(transformed);
  }, [OrdersListConfirmedData]);

  const handlePageChange = (_: any, value: number) => {
    setCurrentPage({ ...currentPage, confirmed: value });
    refetchConfirmedOrders({
      orderNumber: search.confirmed,
      pageSize: 20,
      currentPage: value,
    });
  };

  const handlePageSizeChange = (event: any) => {
    const newSize = event.target.value as number;
    setPageSize(newSize);
    setCurrentPage({ ...currentPage, confirmed: 1 });
    refetchConfirmedOrders({
      orderNumber: search.confirmed,
      pageSize: newSize,
      currentPage: 1,
    });
  };

  const handleSearch = () => {
    setCurrentPage({ ...currentPage, confirmed: 1 });
    refetchConfirmedOrders({
      orderNumber: search.confirmed,
      pageSize: 20,
      currentPage: 1,
    });
  };

  useEffect(() => {
    refetchConfirmedOrders();
    //eslint-disable-next-line
  }, [tabchange]);

  const headCellsForConfirmed = [
    { id: "order", numeric: false, disablePadding: false, label: t("Home_Dashboard.orders.order") },
    { id: "date", numeric: false, disablePadding: false, label: t("Home_Dashboard.orders.date") },
    {
      id: "itemName",
      numeric: false,
      disablePadding: false,
      label: t("Home_Dashboard.orders.itemName"),
    },
    {
      id: "itemPrice",
      numeric: true,
      disablePadding: false,
      label: t("Home_Dashboard.orders.itemPrice"),
    },
    { id: "quantity", numeric: true, disablePadding: false, label: t("Home_Dashboard.orders.quantity") },
    {
      id: "itemTotal",
      numeric: true,
      disablePadding: false,
      label: t("Home_Dashboard.orders.itemTotal"),
    },
  ];

  return (
    <>
      <Toaster />
      <div className="main">
        <div
          className="btn-group"
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            marginBottom: "10px",
          }}
        >
          <TextField
            placeholder={t("Home_Dashboard.orders.search_by")}
            name="search_pending_orders"
            id="search_product"
            value={search.confirmed}
            onChange={(e) =>
              setSearch({ ...search, confirmed: e.target.value })
            }
            variant="outlined"
            className="search-text-field"
          />
          <Button
            onClick={handleSearch}
            className="search-button"
          >
            <Typography className="btn-text">{t("Home_Dashboard.orders.search")}</Typography>
          </Button>
        </div>
        {confirmedOrderError ? (
          <p className="error-text">{confirmedOrderError?.message}</p>
        ) : loadingConfirmedOrder ? (
          <CircularProgress />
        ) : confirmedOrders && confirmedOrders?.length > 0 ? (
          <OrdersTable
            headCells={headCellsForConfirmed}
            rows={confirmedOrders}
            ButtonActions={additionalActionsForConfirmed}
            pageChange={handlePageChange}
            pageType="confirmed"
            pageNumber={ordersConfirmedPageInfo?.current_page}
          />
        ) : (
          <NoProductFound />
        )}

        {confirmedOrders && confirmedOrders?.length > 0 && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              pt: 5,
            }}
            className="deskPagination"
          >
            <Stack direction="row" spacing={2} alignItems="center">
              <Typography variant="body2">{t("Home_Dashboard.orders.item_per_page")}:</Typography>
              <Select
                value={pageSize}
                onChange={handlePageSizeChange}
                variant="outlined"
                size="small"
              >
                {array?.map((size: any) => (
                  <MenuItem key={size} value={size.value}>
                    {size.lable}
                  </MenuItem>
                ))}
              </Select>
            </Stack>
            <Pagination
              count={Math.ceil(80 / pageSize)}
              shape="rounded"
              page={currentPage.confirmed}
              onChange={handlePageChange}
            />
          </Box>
        )}
      </div>
      {menuItems.map(
        (menuItem, index) =>
          selectedMenuItem === menuItem && (
            <div className="mobile-wrapper" key={index}>
              <MobileTable
                headCells={headCellsForConfirmed}
                rows={confirmedOrders}
                ButtonActions={additionalActionsForConfirmed}
                onChange={handlePageChange}
                onRowsPerPageChange={handlePageSizeChange}
                pageSize={pageSize}
                pageIs={currentPage.confirmed}
              />
            </div>
          )
      )}
    </>
  );
};

export default ConfirmedOrder;
