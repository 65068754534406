import "./PasswordReset.css"

import * as mutations from "../../../Operations/Mutations";
import { Divider, Typography, Grid, ListItem, TextField, Button, Box, List } from '@mui/material'
import { CountryContext } from '../../..';

import '../../../../src/mainseller.scss'
import { ChangeEvent, useContext, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Toaster from '../../../Components/ToastMessage';
import { useTranslation } from "react-i18next";

interface FormDataInterface {
    loginInformation: [{
        email: string,
        password: string;
        validated: boolean;
    }];
}
const PasswordReset = () => {
    const {store} = useContext(CountryContext);
    const { t }: { t: (key: string) => string } = useTranslation();
    //eslint-disable-next-line
    const [showPassword, setShowPassword] = useState(false);
    const [passwordMatch, setPasswordMatch] = useState(false);
    // const [login, setLogin] = useState(false);
    // const [userInput, setUserInput] = useState(false);
    // const [enableSubmit, updateEnableSubmit] = useState({status: 'disabled'});
    //eslint-disable-next-line
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    //eslint-disable-next-line
    const [resError, setResError] = useState("");
    const navigate = useNavigate();
    
    const initialFormData: FormDataInterface = {
        loginInformation: [{
            email: '',
            password: '',
            validated: false
        }]
    }
    const [formData, setFormData] = useState<FormDataInterface>(initialFormData);
    let [passwordResetRequest] = useMutation(
            mutations.forgotPwdMutation.RESET_PASSWORD_MUTATION,
            {
                context: {
                    headers: {
                        Connection: "keep-alive",
                        store: store
                    },
                },
                onError: (error) => {
                    console.error("error 66: ",error.message);
                }
            },
        );
    
    const isStrongPassword = (password: any) => {
        const strongPasswordRegex =
            /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%^&+=]).{8,}$/;
        const response = strongPasswordRegex.test(password);
        formData.loginInformation[0].validated = response;
        return response;
    };
    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, section: keyof FormDataInterface) => {
        const { name, value } = e.target;
        setFormData((prevState: FormDataInterface) => ({
            ...prevState,
            [section]: [{
                ...prevState[section][0],
                [name]: value
            }]
        }));
    };
    const matchPassword = (e: any) => {
        if(formData.loginInformation[0].password===e.target.value) {
            setPasswordMatch(true);
        }
        else {
            setPasswordMatch(false);
            formData.loginInformation[0].validated = false;
        }
    }
    const handleSubmit = async (e: any) => {
        e.preventDefault();
        try{
            
            if(formData.loginInformation[0].validated) {
                const token = window.location.search.split("?token=")
                const response = await passwordResetRequest({
                    variables: {
                        email: formData.loginInformation[0].email,    
                        resetPasswordToken: token[1],
                        newPassword: formData.loginInformation[0].password
                    }
                });
                if(response?.data) {
                    setShowErrorAlert(false);
                    toast.success(t("Home.resetPwd.update_toast"), {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setTimeout(() => {
                        navigate("/seller-login");
                    }, 2000);
                }
                
            }
            else {
                setResError(t("Home.resetPwd.invalid_cred"));
                toast.error(t("Home.resetPwd.invalid_cred"), {
                    position: toast.POSITION.TOP_RIGHT
                });
                setShowErrorAlert(true);
            }
        }
        catch(error) {
            setResError(t("Home.resetPwd.invalid_cred"));
            toast.error(t("Home.resetPwd.invalid_cred"), {
                position: toast.POSITION.TOP_RIGHT
            });
            setShowErrorAlert(true);
        }
    }
    return (
        <div className='login-main directionrtl' style={{minHeight:"717px"}}>
            <div className='seller-acc-main'>
            {<Toaster/>}
            <Typography variant='h1'>{t("Home.resetPwd.title")}</Typography>
            {/*showErrorAlert && (
                <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                {resError}
                </Alert>
            )*/}
            <form onSubmit={handleSubmit}>
                <Box sx={{
                    border: "1px solid #DEDCDC",
                    backgroundColor: "#FFFFFF"
                }} className="main-box">
                    <List>
                        <ListItem>
                            <Typography className='title'>
                            {t("Home.resetPwd.title")}
                            </Typography>
                        </ListItem>
                        <Divider />
                        <Grid className='company-upper' container spacing={2} sx={{ padding: '30px' }}>
                            <Grid item xs={12} md={12} lg={12}>
                                <label>{t("Home.resetPwd.email")}</label>
                                <TextField
                                    name='email'
                                    variant="outlined"
                                    className='input-field'
                                    type='text'
                                    onChange={(e) => handleChange(e, 'loginInformation')}
                                />
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                                <label>{t("Home.resetPwd.pwd")}</label>
                                <TextField
                                    name='password'
                                    variant="outlined"
                                    className='input-field'
                                    type={showPassword ? 'text' : 'password'}
                                    onChange={(e) => handleChange(e, 'loginInformation')}
                                />
                                <Typography className='password-msg'>
                                {t("Home.resetPwd.pwd_strength")}: {formData.loginInformation[0].password.length === 0 ? (
                                        <span>{t("Home.resetPwd.no_pwd")}</span>
                                    ) : isStrongPassword(formData.loginInformation[0].password) ? (
                                        <span style={{ color: "green" }}>
                                            {t("Home.resetPwd.strong_pwd")}
                                        </span>
                                    ) : (
                                        <span style={{ color: "red" }}>
                                            {t("Home.resetPwd.weak_pwd")}
                                        </span>
                                    )}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                                <label>{t("Home.resetPwd.cnf_pwd")}</label>
                                <TextField
                                    name='confirm_password'
                                    variant="outlined"
                                    className='input-field'
                                    type={showPassword ? 'text' : 'password'}
                                    onChange={matchPassword}
                                />
                                <Typography className='password-msg'>
                                    { 
                                    (!isStrongPassword(formData.loginInformation[0].password)) ?
                                        <span style={{ color: "red" }}>
                                            {t("Home.resetPwd.weak_pwd")}
                                        </span>
                                    : (isStrongPassword(formData.loginInformation[0].password) && passwordMatch) ?
                                        <span style={{ color: "green" }}>
                                           {t("Home.resetPwd.pwd_matched")}
                                        </span>
                                    :
                                        <span style={{ color: "red" }}>
                                            {t("Home.resetPwd.pwd__not_matched")}
                                        </span>
                                    }
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sx={{ maxWidth: '100% !important' }}>
                                <Box sx={{ width: '100%', marginTop: '30px' }}>
                                    <Button className='create-btn' type='submit'>
                                    {t("Home.resetPwd.update_pwd")}
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </List>
                </Box>
            </form>
            </div>
        </div>
    )
}

export default PasswordReset;