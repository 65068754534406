import React, { useContext, useState } from "react";
import "../Navbar/Navbar.css";
import { Button, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

import { Link } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import CloseIcon from "@mui/icons-material/Close";
import sellerhublogoEnglish from "../../assets/images/seller-hub-logo.svg";
import sellerhublogoArabic from "../../assets/images/arabicSellerLogo.png";
import { useTranslation } from "react-i18next";
import { CountryContext } from "../..";
import i18n from "../../i18n";
import CountryFlag from "../New_Topbar/CountryFlag";

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

const drawerWidth = "100%";

const paths = [
  "/",
  "/referral-fee",
  "/faq",
  "/advertise",
  "/seller-training",
  "https://www.jebelz.com",
];

export default function Navbar(props: Props) {
  const { window } = props;
  const { t }: { t: (key: string) => string } = useTranslation();
  const { toggleLanguage , store} = useContext(CountryContext);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
    setIsMenuOpen(!isMenuOpen);
  };

  const navItems = [
    t("Home.navbar.HOME"),
    t("Home.navbar.REFERRAL FEE"),
    t("Home.navbar.FAQ"),
    t("Home.navbar.ADVERTISE"),
    t("Home.navbar.SELLER TRAINING"),
    "JEBELZ.COM",
  ];

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <div className="navbar-menu-block directionrtl">
        <List className="navbar-menulist">
          {navItems.map((item, index) => (
            <ListItem key={item} disablePadding>
              <Link
                to={paths[index]}
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  width: "100%",
                }}
              >
                <ListItemButton sx={{ textAlign: "left" }}>
                  <ListItemText primary={item} />
                </ListItemButton>
              </Link>
            </ListItem>
          ))}
        </List>
      </div>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }} className="mobile-menu-block directionrtl">
      {/* <CssBaseline /> */}
      <AppBar component="nav" className="main-top-bar">
        <Toolbar className="main-top-toolbar">
          <div className="top-bar">
            <div>
              <img src={ i18n.language === "ar" ? sellerhublogoArabic : sellerhublogoEnglish} alt="" />
              {/* <img src={sellerhublogoEnglish} alt="" /> */}
            </div>
            <IconButton
              className="nav-toggle-btn"
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { md: "none" } }}
            >
              {isMenuOpen ? <CloseIcon /> : <MenuIcon />}
            </IconButton>
            <Box sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
              {navItems.map((item, index) => (
                <Link
                  key={item}
                  to={paths[index]}
                  style={{ textDecoration: "none", color: "#fff" }}
                >
                  <Button sx={{ color: "#fff" }}>{item}</Button>
                </Link>
              ))}
            </Box>
          </div>
          <div
            className="top-bar-login"
            style={{
              marginLeft: i18n.language === "ar" ? "unset" : "auto",
              marginRight: i18n.language === "ar" ? "auto" : "unset",
            }}
          >
            <div className="form-field-section">
              <div className="register-btn-block">
              {store !== "sell_in" &&  <Button
                  className="btn btn-primary language-toggle"
                  style={{
                    backgroundColor: "transparent",
                    color: "inherit",
                    textTransform: "unset",
                    // opacity: store === "sell_in" ? 0.5 : 1,
                    border: "none",
                    fontWeight:"700"
                  }}
                  onClick={toggleLanguage}
                  // store === "sell_in" ? undefined :
                  // disabled={store === "sell_in"}
                >
                  {t("switchLanguage")}
                </Button>}
                <CountryFlag />
                <Link to="/seller-login">
                  <Button className="login-btn" variant="contained">
                 {t("Home.navbar.log_in")}
                  </Button>
                </Link>
                <Link to="/register">
                  <Button className="login-btn" variant="contained">
                  {t("Home.navbar.register")}
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          anchor={i18n.language === "ar" ? "left" : "right"}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
      {/* <Box component="main" sx={{ p: 3 }}>
        <Toolbar />
       
      </Box> */}
    </Box>
  );
}
