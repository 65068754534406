import { Box, Button, Checkbox, Divider, Grid, List, ListItem, TextField, Typography } from '@mui/material'
import { Link } from 'react-router-dom';
import '../../../src/mainseller.scss'
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import * as mutations from "../../Operations/Mutations";
import Toaster from '../ToastMessage';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CountryContext } from '../..';
import { useTranslation } from 'react-i18next';


interface FormDataInterface {
    companyInformation: [{
        company: string;
        firstname: string;
        lastname: string;
        email: string;
        contact_number: string;
    }];
    pickupLocation: [{
        vendor_address: string;
        google_map: string;
        city: string;
        region: string;
    }];
    documents: [{
        trade_license: [{
            name: string,
            base64_encoded_file: string
        }],
        vat_certificate: [{
            name: string,
            base64_encoded_file: string
        }]
    }];
    // bankDetailsInd: [{
    //     beneficiaryName: string;
    //     bankName: string;
    //     ifscCode: string;
    //     accountNumber: string;
    // }];
    bankDetails: [{
        bank_account_name: string;
        bank_name: string;
        bank_branch_number: string;
        bank_account_number: string;
        bank_account_iban: string;
        bank_swift_code: string;
    }];
    loginInformation: [{
        password: string;
    }];
    storeName: [{
        shop_title: string;
    }];
}


const MainForm = () => {
    const { t }: { t: (key: string) => string } = useTranslation();
    const navigate = useNavigate();

    // const { register, handleSubmit, formState: { errors } } = useForm<FormDataInterface>();

    const [showPassword, setShowPassword] = useState(false)
    const [conditionsChecked, setConditionsChecked] = useState(false);
    const [fillBankDetails, setFillBankDetails] = useState(false);
    const [confirmPwd, setConfirmPwd] = useState("")
    const initialFormData: FormDataInterface = {
        companyInformation: [{
            company: '',
            firstname: '',
            lastname: '',
            email: '',
            contact_number: ''
        }],
        pickupLocation: [{
            vendor_address: '',
            google_map: '',
            city: '',
            region: ''
        }],
        documents: [{
            trade_license: [{
                name: "",
                base64_encoded_file: "",
            }],
            vat_certificate: [{
                name: "",
                base64_encoded_file: "",
            }]
        }],
        bankDetails: [{
            bank_account_name: '',
            bank_name: '',
            bank_branch_number: '',
            bank_account_number: '',
            bank_account_iban: '',
            bank_swift_code: ''
        }],
        loginInformation: [{
            password: '',
        }],
        storeName: [{
            shop_title: ""
        }]
    }

    const [formData, setFormData] = useState<FormDataInterface>(initialFormData);

    //eslint-disable-next-line
    const [errors, setErrors] = useState<{ [key: string]: string }>({});

    const {store} = useContext(CountryContext);

    let [createSeller] = useMutation(
        mutations.createSellerAccountMutation.CREATE_SELLER_ACCOUNT,
        {
            context: {
                headers: {
                    Connection: "keep-alive",
                    store: store,
                },
            },
        }
    );

    useEffect(() => {
        console.log("checked bank", fillBankDetails)
    }, [fillBankDetails])

    //eslint-disable-next-line
    const resetForm = () => {
        setFormData(initialFormData);
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, section: keyof FormDataInterface) => {
        const { name, value } = e.target;
        // setFormData((prevState: any) => ({
        //     ...prevState,
        //     [section]: {
        //         ...prevState[section],
        //         [name]: value
        //     }
        // }));
        setFormData((prevState: FormDataInterface) => ({
            ...prevState,
            [section]: [{
                ...prevState[section][0],
                [name]: value
            }]
        }));
        //check for errors
        // const trimmedValue = value.trim();
        // setErrors(prevErrors => ({
        //     ...prevErrors,
        //     [section]: [{
        //         ...prevErrors[section][0],
        //         [name]: trimmedValue === '' // Update error state based on the trimmed value
        //     }]
        // }));
    };

    const handleTermsConditionsCheckbox = (event: any) => {
        setConditionsChecked(event.target.checked);
    };

    const handleCompanyFileUpload = (event: any) => {
        const file = event.target.files[0];
        if (!file) {
            toast.error(t("Home.register.no_file_selected_err"), {
                position: toast.POSITION.TOP_RIGHT
            });
            console.error('No file selected');
            return;
        }
        const newFormData = { ...formData };
        const fileExtension = file?.name.split('.').pop()?.toLowerCase() || '';

        const reader = new FileReader();
        reader.onload = () => {
            if (reader.result) {
                const base64String = btoa(new Uint8Array(reader.result as ArrayBuffer).reduce((data, byte) => data + String.fromCharCode(byte), ''));
                const finalBase64String = `data:application/${fileExtension};base64,${base64String}`;

                newFormData.documents[0].trade_license[0] = {
                    name: file.name,
                    base64_encoded_file: finalBase64String
                };
                setFormData(newFormData);
                toast.success('File added successfully', {
                    position: toast.POSITION.TOP_RIGHT
                  });
            } else {
                toast.error(t("Home.register.err_reading_file"), {
                    position: toast.POSITION.TOP_RIGHT
                  });
            }
        };
        reader.readAsArrayBuffer(file);
    };

    const handleTaxFileUpload = (event: any) => {
        const file = event.target.files[0];
        if (!file) {
            toast.error(t("Home.register.no_file_selected_err"), {
                position: toast.POSITION.TOP_RIGHT
            });
            console.error('No file selected');
            return;
        }
        const newFormData = { ...formData };
        const fileExtension = file?.name.split('.').pop()?.toLowerCase() || '';

        const reader = new FileReader();
        reader.onload = () => {
            if (reader.result) {
                const base64String = btoa(new Uint8Array(reader.result as ArrayBuffer).reduce((data, byte) => data + String.fromCharCode(byte), ''));
                const finalBase64String = `data:application/${fileExtension};base64,${base64String}`;
                // Update the gstCertificate field in the documents section of formData
                newFormData.documents[0].vat_certificate[0] = {
                    name: file.name,
                    base64_encoded_file: finalBase64String
                };
                setFormData(newFormData);
                toast.success(t("Home.register.file_added_success_msg"), {
                    position: toast.POSITION.TOP_RIGHT
                  });
            } else {
                toast.error(t("Home.register.err_reading_file"), {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        };
        reader.readAsArrayBuffer(file);
    };

    

    const handleFormSubmit = async (e: any) => {
        e.preventDefault();
    
        // Check if fillBankDetails is true
        if (fillBankDetails) {
            if (
                !formData?.companyInformation[0]?.company ||
                !formData?.companyInformation[0]?.firstname ||
                !formData?.companyInformation[0]?.lastname ||
                !formData?.companyInformation[0]?.email ||
                !formData?.companyInformation[0]?.contact_number ||
                !formData?.pickupLocation[0]?.vendor_address ||
                !formData?.pickupLocation[0]?.google_map ||
                !formData?.pickupLocation[0]?.city ||
                !formData?.pickupLocation[0]?.region ||
                !formData?.documents[0]?.trade_license[0]?.base64_encoded_file ||
                !formData?.documents[0]?.vat_certificate[0]?.base64_encoded_file ||
                !formData?.storeName[0]?.shop_title
            ) {
                toast.error(t("Home.register.required_fill_err"), {
                    position: toast.POSITION.TOP_RIGHT
                });
                // alert("Please fill in all required fields");
                return;
            }
        } else {
            if (
                !formData?.companyInformation[0]?.company ||
                !formData?.companyInformation[0]?.firstname ||
                !formData?.companyInformation[0]?.lastname ||
                !formData?.companyInformation[0]?.email ||
                !formData?.companyInformation[0]?.contact_number ||
                !formData?.pickupLocation[0]?.vendor_address ||
                !formData?.pickupLocation[0]?.google_map ||
                !formData?.pickupLocation[0]?.city ||
                !formData?.pickupLocation[0]?.region ||
                !formData?.documents[0]?.trade_license[0]?.base64_encoded_file ||
                !formData?.documents[0]?.vat_certificate[0]?.base64_encoded_file ||
                !formData?.bankDetails[0]?.bank_account_name ||
                !formData?.bankDetails[0]?.bank_name ||
                !formData?.bankDetails[0]?.bank_branch_number ||
                !formData?.bankDetails[0]?.bank_account_number ||
                !formData?.bankDetails[0]?.bank_account_iban ||
                !formData?.bankDetails[0]?.bank_swift_code ||
                !formData?.storeName[0]?.shop_title
            ) {
                toast.error('Please fill in all required fields', {
                    position: toast.POSITION.TOP_RIGHT
                });
                // alert("Please fill in all required fields");
                return;
            }
        }
    
        if (formData?.loginInformation[0]?.password !== confirmPwd) {
            toast.error(t("Home.register.pwd_not_matched"), {
                position: toast.POSITION.TOP_RIGHT
            });
            // alert("Passwords do not match");
            return;
        }
    
        if (!conditionsChecked) {
            toast.error(t("Home.register.term_and_condition_err"), {
                position: toast.POSITION.TOP_RIGHT
            });
            // alert("Please check Terms & Conditions");
            return;
        }
        try {
            await createSeller({
                variables: { input: formData }
            })
            toast.success(t("Home.register.seller_success_msg"), {
                position: toast.POSITION.TOP_RIGHT,
                onClose: () => {
                    setTimeout(() => {
                        navigate("/seller-login");
                    }, 2000);
                }
            });
            // navigate("/seller-login") 
        } catch (error) {
            toast.error(t("Home.register.create_acc_err"), {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    const isStrongPassword = (password: any) => {
        const strongPasswordRegex =
            /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%^&+=]).{8,}$/;
        return strongPasswordRegex.test(password);
    };

    return (
        <>
            <div className='seller-acc-main directionrtl'>
                {<Toaster/>}
                <Typography variant='h1'>{t("Home.register.create_acc")}</Typography>
                <form onSubmit={handleFormSubmit}>  
                    {/* company */}
                    <Box sx={{
                        border: "1px solid #DEDCDC",
                        backgroundColor: "#FFFFFF"
                    }} className="main-box">
                        <List>
                            <ListItem>
                                <Typography className='title'>
                                {t("Home.register.company_info")}
                                </Typography>
                            </ListItem>
                            <Divider />
                            <Grid className='company-upper' container spacing={2} sx={{ padding: '30px' }}>
                                <Grid item xs={12} md={6} lg={6}>
                                    <label>{t("Home.register.company_name")}</label>
                                    <TextField
                                        variant="outlined"
                                        className='input-field'
                                        value={formData.companyInformation[0].company}
                                        name='company'
                                        onChange={(e) => handleChange(e, 'companyInformation')}
                                        // {...register('companyInformation.companyLegalName', { required: true })}
                                        // error={errors.companyInformation[0]?.company}
                                        // helperText={errors.companyInformation[0]?.company && "Company Legal Name is required"}
                                    />
                                    {errors['companyInformation.company'] && <span>{errors['companyInformation.company']}</span>}
                                </Grid>
                                <Grid item xs={12} md={6} lg={3}>
                                    <label>{t("Home.register.f_name")}</label>
                                    <TextField
                                        name='firstname'
                                        value={formData.companyInformation[0].firstname}
                                        onChange={(e) => handleChange(e, 'companyInformation')}
                                        variant="outlined"
                                        className='input-field'
                                        // {...register('companyInformation.firstName', { required: true })}
                                        // error={errors.companyInformation[0]?.firstname}
                                        // helperText={errors.companyInformation[0]?.firstname && "First Name is required"}
                                    />
                                    {errors['companyInformation.firstname'] && <span>{errors['companyInformation.firstname']}</span>}
                                </Grid>
                                <Grid item xs={12} md={6} lg={3}>
                                    <label> {t("Home.register.l_name")}</label>
                                    <TextField
                                        name='lastname'
                                        value={formData.companyInformation[0].lastname}
                                        onChange={(e) => handleChange(e, 'companyInformation')}
                                        variant="outlined"
                                        className='input-field'
                                        // {...register('companyInformation.lastName', { required: true })}
                                        // error={errors.companyInformation[0]?.lastname}
                                        // helperText={errors.companyInformation[0]?.lastname && "Last Name is required"}
                                    />
                                    {errors['companyInformation.lastname'] && <span>{errors['companyInformation.lastname']}</span>}
                                </Grid>
                                <Grid item xs={12} md={6} lg={6}>
                                    <label>{t("Home.register.email")}</label>
                                    <TextField
                                        name='email'
                                        value={formData.companyInformation[0].email}
                                        onChange={(e) => handleChange(e, 'companyInformation')}
                                        variant="outlined"
                                        className='input-field'
                                        type='email'
                                        autoComplete='new-email'
                                        // {...register('companyInformation.emailAddress', { required: true })}
                                        // error={errors.companyInformation[0]?.email}
                                        // helperText={errors.companyInformation[0]?.email && "Email Address is required"}
                                    />
                                    {errors['companyInformation.email'] && <span>{errors['companyInformation.email']}</span>}
                                </Grid>
                                <Grid item xs={12} md={6} lg={6}>
                                    <label>{t("Home.register.mobille_no")}</label>
                                    <TextField
                                        name='contact_number'
                                        value={formData.companyInformation[0].contact_number}
                                        onChange={(e) => handleChange(e, 'companyInformation')}
                                        variant="outlined"
                                        className='input-field'
                                        // {...register('companyInformation.mobileNumber', { required: true })}
                                        // error={errors.companyInformation[0]?.contact_number}
                                        // helperText={errors.companyInformation[0]?.contact_number && "Mobile Number is required"}
                                    />
                                    {errors['companyInformation.contact_number'] && <span>{errors['companyInformation.contact_number']}</span>}
                                </Grid>
                            </Grid>
                        </List>
                    </Box>
                    {/* orderpickup */}
                    <Box sx={{
                        border: "1px solid #DEDCDC",
                        backgroundColor: "#FFFFFF"
                    }} className="main-box">
                        <List>
                            <ListItem>
                                <Typography className='title'>
                                {t("Home.register.order_pickup_details")}
                                </Typography>
                            </ListItem>
                            <Divider />
                            <Grid className='company-upper' container spacing={2} sx={{ padding: '30px' }}>
                                <Grid item xs={12} md={6} lg={6}>
                                    <label>{t("Home.register.pckp_add")}</label>
                                    <TextField
                                        variant="outlined"
                                        className='input-field'
                                        value={formData.pickupLocation[0].vendor_address}
                                        name='vendor_address'
                                        onChange={(e) => handleChange(e, 'pickupLocation')}
                                    //     {...register('orderPickupLocation.pickupAddress', { required: true })}
                                    // error={!!errors.orderPickupLocation?.pickupAddress}
                                    // helperText={errors.orderPickupLocation?.pickupAddress && "Pickup Address is required"}
                                    />
                                    {errors['pickupLocation.vendor_address'] && <span>{errors['pickupLocation.vendor_address']}</span>}
                                </Grid>
                                <Grid item xs={12} md={6} lg={6}>
                                    <label className='unastrik'> {t("Home.register.google_map_link")}</label>
                                    <TextField
                                        variant="outlined"
                                        className='input-field'
                                        value={formData.pickupLocation[0].google_map}
                                        name='google_map'
                                        onChange={(e) => handleChange(e, 'pickupLocation')}
                                    //     {...register('orderPickupLocation.googleMapLink', { required: true })}
                                    // error={!!errors.orderPickupLocation?.googleMapLink}
                                    // helperText={errors.orderPickupLocation?.googleMapLink && "Google Map Link is required"}
                                    />
                                    {errors['pickupLocation.google_map'] && <span>{errors['pickupLocation.google_map']}</span>}
                                </Grid>
                                <Grid item xs={12} md={6} lg={6}>
                                    <label>{t("Home.register.city")}</label>
                                    <TextField
                                        variant="outlined"
                                        className='input-field'
                                        value={formData.pickupLocation[0].city}
                                        name='city'
                                        onChange={(e) => handleChange(e, 'pickupLocation')}
                                    // {...register('orderPickupLocation.city', { required: true })}
                                    // error={!!errors.orderPickupLocation?.city}
                                    // helperText={errors.orderPickupLocation?.city && "City is required"}
                                    />
                                    {errors['pickupLocation.city'] && <span>{errors['pickupLocation.city']}</span>}
                                </Grid>
                                <Grid item xs={12} md={6} lg={6}>
                                    <label className='unastrik'>{t("Home.register.state")}</label>
                                    <TextField
                                        variant="outlined"
                                        className='input-field'
                                        value={formData.pickupLocation[0].region}
                                        name='region'
                                        onChange={(e) => handleChange(e, 'pickupLocation')}
                                    // {...register('orderPickupLocation.state', { required: true })}
                                    // error={!!errors.orderPickupLocation?.state}
                                    // helperText={errors.orderPickupLocation?.state && "State is required"}
                                    />
                                    {errors['pickupLocation.region'] && <span>{errors['pickupLocation.region']}</span>}
                                </Grid>
                            </Grid>

                        </List>
                    </Box>
                    {/* document */}
                    <Box sx={{
                        border: "1px solid #DEDCDC",
                        backgroundColor: "#FFFFFF"
                    }} className="main-box">
                        <List>
                            <ListItem>
                                <Typography className='title'>
                                {t("Home.register.document")}
                                </Typography>
                            </ListItem>
                            <Divider />
                            <Grid className='company-upper' container spacing={2} sx={{ padding: '30px' }}>
                                <Grid item xs={12} sm={12} md={12} lg={6}>
                                    <label>{t("Home.register.company_inc")}</label>
                                    <TextField
                                        variant="outlined"
                                        className='input-field file-input'
                                        type='file'
                                        // value={formData.documents[0].incorporationCertificate}
                                        name='trade_license'
                                        onChange={handleCompanyFileUpload}
                                    //     {...register('documents.incorporationCertificate', { required: true })}
                                    //     error={!!errors.documents?.incorporationCertificate}
                                    // helperText={errors.documents?.incorporationCertificate && "incorporation Certificate is required"}
                                    />
                                    {errors['incorporationCertificate.trade_license'] && <span>{errors['incorporationCertificate.trade_license']}</span>}
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={6}>
                                    <label className='vat-title-label'> {t("Home.register.gst_certi")}</label>
                                    <TextField
                                        variant="outlined"
                                        className='input-field file-input'
                                        type='file'
                                        // value={formData.documents[0].gstCertificate}
                                        name='vat_certificate'
                                        onChange={handleTaxFileUpload}
                                    // {...register('documents.gstCertificate', { required: true })}
                                    // error={!!errors.documents?.gstCertificate}
                                    // helperText={errors.documents?.gstCertificate && "GST Certificate is required"}
                                    />
                                    {errors['gstCertificate.vat_certificate'] && <span>{errors['companyInformation.vat_certificate']}</span>}
                                </Grid>
                                <Grid item xs={12} md={12} sx={{ maxWidth: '100% !important' }}>
                                    <ListItem sx={{ paddingLeft: '0' }}>
                                        <Typography variant='h6'>
                                        {t("Home.register.gst_certi_desc")}
                                        </Typography>
                                    </ListItem>
                                </Grid>
                            </Grid>
                        </List>
                    </Box>
                    {/* bankDetailsGCC */}
                    <Box sx={{
                        border: "1px solid #DEDCDC",
                        backgroundColor: "#FFFFFF"
                    }} className="main-box">
                        <List>
                            <ListItem>
                                <Typography className='title'>
                                {t("Home.register.bank_details")}
                                </Typography>
                                <Checkbox
                                    checked={fillBankDetails}
                                    onChange={() => setFillBankDetails(!fillBankDetails)}
                                />
                                <Typography className='checkbox-text'>
                                {t("Home.register.bank_details_checkbox")}
                                </Typography>
                            </ListItem>
                            <Divider />
                            <Grid className='company-upper' container spacing={2} sx={{ padding: '30px' }}>
                                <Grid item xs={12} md={6} lg={6}>
                                    <label>{t("Home.register.beneficiary_name")}</label>
                                    <TextField
                                        variant="outlined"
                                        className='input-field'
                                        value={formData?.bankDetails?.[0]?.bank_account_name}
                                        name='bank_account_name'
                                        onChange={(e) => handleChange(e, 'bankDetails')}
                                    //                             {...register('bankDetailsGCC.beneficiaryName', { required: true })}
                                    // error={!!errors.bankDetailsGCC?.beneficiaryName}
                                    // helperText={errors.bankDetailsGCC?.beneficiaryName && "Beneficiary Name is required"}
                                    />
                                    {errors['bankDetails.bank_account_name'] && <span>{errors['bankDetails.bank_account_name']}</span>}
                                </Grid>
                                <Grid item xs={12} md={6} lg={6}>
                                    <label>{t("Home.register.bank_name")}</label>
                                    <TextField
                                        variant="outlined"
                                        className='input-field'
                                        value={formData?.bankDetails?.[0]?.bank_name}
                                        name='bank_name'
                                        onChange={(e) => handleChange(e, 'bankDetails')}
                                    // {...register('bankDetailsGCC.bankName', { required: true })}
                                    // error={!!errors.bankDetailsGCC?.bankName}
                                    // helperText={errors.bankDetailsGCC?.bankName && "Bank Name is required"}
                                    />
                                    {errors['bankDetails.bank_name'] && <span>{errors['bankDetails.bank_name']}</span>}
                                </Grid>
                                <Grid item xs={12} md={6} lg={6}>
                                    <label>{t("Home.register.branch")}</label>
                                    <TextField
                                        variant="outlined"
                                        className='input-field'
                                        value={formData?.bankDetails?.[0]?.bank_branch_number}
                                        name='bank_branch_number'
                                        onChange={(e) => handleChange(e, 'bankDetails')}
                                    // {...register('bankDetailsGCC.branch', { required: true })}
                                    // error={!!errors.bankDetailsGCC?.branch}
                                    // helperText={errors.bankDetailsGCC?.branch && "Branch is required"}
                                    />
                                    {errors['bankDetails.bank_branch_number'] && <span>{errors['bankDetails.bank_branch_number']}</span>}
                                </Grid>
                                <Grid item xs={12} md={6} lg={6}>
                                    <label>{t("Home.register.acc_no")}</label>
                                    <TextField id="outlined-basic"
                                        variant="outlined"
                                        className='input-field'
                                        value={formData?.bankDetails?.[0]?.bank_account_number}
                                        name='bank_account_number'
                                        onChange={(e) => handleChange(e, 'bankDetails')}
                                    // {...register('bankDetailsGCC.accountNumber', { required: true })}
                                    // error={!!errors.bankDetailsGCC?.accountNumber}
                                    // helperText={errors.bankDetailsGCC?.accountNumber && "Account Number is required"}
                                    />
                                    {errors['bankDetails.bank_account_number'] && <span>{errors['bankDetails.bank_account_number']}</span>}
                                </Grid>
                                <Grid item xs={12} md={6} lg={6}>
                                    <label> {t("Home.register.IBAN")}</label>
                                    <TextField
                                        variant="outlined"
                                        className='input-field'
                                        value={formData?.bankDetails?.[0]?.bank_account_iban}
                                        name='bank_account_iban'
                                        onChange={(e) => handleChange(e, 'bankDetails')}
                                    // {...register('bankDetailsGCC.iban', { required: true })}
                                    // error={!!errors.bankDetailsGCC?.iban}
                                    // helperText={errors.bankDetailsGCC?.iban && "IBAN is required"}
                                    />
                                    {errors['bankDetails.bank_account_iban'] && <span>{errors['bankDetails.bank_account_iban']}</span>}
                                </Grid>
                                <Grid item xs={12} md={6} lg={6}>
                                    <label>{t("Home.register.swift_code")}</label>
                                    <TextField
                                        variant="outlined"
                                        className='input-field'
                                        value={formData?.bankDetails[0].bank_swift_code}
                                        name='bank_swift_code'
                                        onChange={(e) => handleChange(e, 'bankDetails')}
                                    // {...register('bankDetailsGCC.swiftCode', { required: true })}
                                    // error={!!errors.bankDetailsGCC?.swiftCode}
                                    // helperText={errors.bankDetailsGCC?.swiftCode && "Swift Code is required"}
                                    />
                                    {errors['bankDetails.bank_swift_code'] && <span>{errors['bankDetails.bank_swift_code']}</span>}
                                </Grid>
                            </Grid>

                        </List>
                    </Box>
                    {/* loginInfo */}
                    <Box sx={{
                        border: "1px solid #DEDCDC",
                        backgroundColor: "#FFFFFF"
                    }} className="main-box">
                        <List>
                            <ListItem>
                                <Typography className='title'>
                                {t("Home.register.login_info")}
                                </Typography>
                            </ListItem>
                            <Divider />
                            <Grid className='company-upper' container spacing={2} sx={{ padding: '30px' }}>
                                <Grid item xs={12} md={6} lg={6}>
                                    <label>{t("Home.register.pwd")}</label>
                                    <TextField
                                        variant="outlined"
                                        className='input-field'
                                        value={formData.loginInformation[0].password}
                                        name='password'
                                        autoComplete='new-password'
                                        onChange={(e) => handleChange(e, 'loginInformation')}
                                    // {...register('loginInfo.password', { required: true, minLength: 8 })}
                                    // error={!!errors.loginInfo?.password}
                                    // helperText={errors.loginInfo?.password ? "Password is required and must be at least 8 characters long" : " "}
                                    />
                                    <Typography className='password-msg'>
                                    {t("Home.register.pwd_strength")}: {formData.loginInformation[0].password.length === 0 ? (
                                            <span>{t("Home.register.no_pwd")}</span>
                                        ) : isStrongPassword(formData.loginInformation[0].password) ? (
                                            <span style={{ color: "green" }}>
                                                {t("Home.register.strong_pwd")}
                                            </span>
                                        ) : (
                                            <span style={{ color: "red" }}>
                                                {t("Home.register.weak_pwd")}
                                            </span>
                                        )}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6} lg={6}>
                                    <label>{t("Home.register.cnf_pwd")}</label>
                                    <TextField
                                        variant="outlined"
                                        className='input-field'
                                        type={showPassword ? 'text' : 'password'}
                                        value={confirmPwd}
                                        name='confirmPassword'
                                        autoComplete='new-password'
                                        onChange={(e) => setConfirmPwd(e.target.value)}
                                    // {...register('loginInfo.confirmPassword', {
                                    //      required: true,
                                    //     minLength: 8 })}
                                    //     error={!!errors.loginInfo?.confirmPassword}
                                    //     helperText={errors.loginInfo?.confirmPassword ? "Passwords do not match" : " "}
                                    />
                                    <ListItem sx={{ paddingLeft: '0', paddingTop: '0px' }}>
                                        <Checkbox
                                            checked={showPassword}
                                            onChange={() => setShowPassword(!showPassword)}
                                        />
                                        <Typography className='checkbox-text'>
                                        {t("Home.register.show_pwd")}
                                        </Typography>
                                    </ListItem>
                                </Grid>
                                <Grid item xs={12} sx={{ maxWidth: '100% !important' }}>
                                    <List
                                        sx={{
                                            listStyleType: 'disc',
                                            listStylePosition: 'inside',
                                            paddingTop: '0px'
                                        }}
                                        className='list-items'
                                    >
                                        <ListItem sx={{ display: 'list-item', paddingLeft: '5px', paddingBottom: '0px' }}>{t("Home.register.pwd_desc_1")}</ListItem>
                                        <ListItem sx={{ display: 'list-item', paddingLeft: '5px', paddingBottom: '0px' }}>{t("Home.register.pwd_desc_2")}</ListItem>
                                        <ListItem sx={{ display: 'list-item', paddingLeft: '5px', paddingBottom: '0px' }}>{t("Home.register.pwd_desc_3")}</ListItem>
                                        <ListItem sx={{ display: 'list-item', paddingLeft: '5px', paddingBottom: '0px' }}>{t("Home.register.pwd_desc_4")}</ListItem>
                                    </List>
                                </Grid>
                            </Grid>
                        </List>
                    </Box>
                    {/* storeName */}
                    <Box sx={{
                        border: "1px solid #DEDCDC",
                        backgroundColor: "#FFFFFF"
                    }} className="main-box">
                        <List>
                            <ListItem>
                                <Typography className='title'>
                                {t("Home.register.store_name")}
                                </Typography>
                            </ListItem>
                            <Divider />
                            <Grid className='company-upper' container spacing={2} sx={{ padding: '30px' }}>
                                <Grid item xs={12} sx={{ maxWidth: '100% !important' }}>
                                    <label>{t("Home.register.store_desc")}</label>
                                    <TextField
                                        variant="outlined"
                                        className='input-field'
                                        value={formData.storeName[0].shop_title}
                                        name='shop_title'
                                        onChange={(e) => handleChange(e, 'storeName')}
                                    //{...register('storeName.name', { required: true })}
                                    // error={!!errors.storeName?.name}
                                    // helperText={errors.storeName?.name ? "Store Name is required" : " "}

                                    />
                                    {errors['storeName.shop_title'] && <span>{errors['storeName.shop_title']}</span>}
                                </Grid>
                                <Grid item xs={12} sx={{ maxWidth: '100% !important' }}>
                                    <ListItem sx={{ padding: '0px' }}>
                                        <Checkbox
                                            checked={conditionsChecked}
                                            onChange={handleTermsConditionsCheckbox}
                                        />
                                        <Typography className='checkbox-text'>{t("Home.register.store_desc_check")}<Link to={(store==="sell_in") ? `../India-Jebelz-SellerAgreement.pdf`:`../JebelzSellerAgreement.pdf`} target='_blank'>{t("Home.register.term_and_condition")}</Link></Typography>
                                    </ListItem>
                                    <ListItem>
                                        <Typography className='checkbox-text'>
                                        {t("Home.register.already_acc_desc")} <Link to="/seller-login">{t("Home.register.login_click_desc")}</Link>
                                        </Typography>
                                    </ListItem>
                                </Grid>
                            </Grid>
                        </List>
                    </Box>
                    <Box sx={{ width: '100%', marginTop: '30px' }}>
                        <Button type='submit' className='create-btn'>{t("Home.register.create_an_acc")}</Button>
                    </Box>
                </form>
            </div>
        </>
    )
}

export default MainForm