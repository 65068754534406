import { useMutation } from '@apollo/client';
import { Box, Button, Divider, Grid, TextField, Typography } from '@mui/material'
import React, { useContext, useState } from 'react'
import { settingsMutation } from '../../../Operations/Mutations';
import { toast } from 'react-toastify';
import Toaster from '../../../Components/ToastMessage';
import { useNavigate } from 'react-router-dom';
import { CountryContext } from '../../..';
import { useTranslation } from 'react-i18next';

const EditBankDetails = () => {
  const [formDataForBank, setFormDataForBank] = useState({
    bankAccountName: '',
    bankName: '',
    ibanNumber: '',
    accountNumber: '',
    swiftCode: '',
    branchName: ''
  });
  const { t }: { t: (key: string) => string } = useTranslation();
  const loggedInToken = localStorage.getItem("loggedInCust");
  const navigate = useNavigate()
  const {store} = useContext(CountryContext);

  const [updateBankDetails] = useMutation(
    settingsMutation.UPDATE_BANK_DETAIL_MUTATION,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store,
        },
      },
    }
  );

  const handleBankFormData = (e: any) => {
    const { name, value } = e.target;
    setFormDataForBank({ ...formDataForBank, [name]: value });
  };

  const formForApi = {
    bankDetails:[{
      bank_name: formDataForBank.bankName,
      bank_branch_number: formDataForBank.branchName,
      bank_account_name: formDataForBank.bankAccountName,
      bank_account_number: formDataForBank.accountNumber,
      bank_account_iban: formDataForBank.ibanNumber,
      bank_swift_code: formDataForBank.swiftCode,
    }]
  }

  const saveBankFormData = async () => {
    try{
      await updateBankDetails({
        variables : {
          input : formForApi
        }
      });
      toast.success(t("Home_Dashboard.bankDetails.success_toast"), {
        position: toast.POSITION.TOP_RIGHT
    });
    } catch(error){
      toast.error(t("Home_Dashboard.bankDetails.err_toast"), {
        position: toast.POSITION.TOP_RIGHT
    });
    }
  };

  const backToSettings = () => {
    navigate("/dashboard/settings")
  }

  return (
    <Box>
      <Grid container spacing={2} className="change-name bank-details">
        <Toaster/>
        <Grid item xs={12} sm={12} md={12} className='settings-header'>
          <Typography variant='h5'>{t("Home_Dashboard.bankDetails.title")}</Typography>
        </Grid>
        <Divider className='divider' />
        <Grid item xs={12} sm={12} md={12} className='title text-title'>
          <Typography variant='h6'>{t("Home_Dashboard.bankDetails.desc")}</Typography>
        </Grid>
        <Grid container spacing={2} xs={12} sm={12} md={12} lg={9} className='form-details'>
          <Grid item xs={12} sm={12} md={6} className='name'>
            <label>{t("Home_Dashboard.bankDetails.bank_acc_name")}</label>
            <TextField
              variant="outlined"
              className='input-field'
              name='bankAccountName'
              value={formDataForBank.bankAccountName}
              onChange={handleBankFormData}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} className='name'>
            <label>{t("Home_Dashboard.bankDetails.bank_name")}</label>
            <TextField
              variant="outlined"
              className='input-field'
              name='bankName'
              value={formDataForBank.bankName}
              onChange={handleBankFormData}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} className='name'>
            <label>{t("Home_Dashboard.bankDetails.iban_num")}</label>
            <TextField
              variant="outlined"
              className='input-field'
              name='ibanNumber'
              value={formDataForBank.ibanNumber}
              onChange={handleBankFormData}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} className='name'>
            <label>{t("Home_Dashboard.bankDetails.acc_num")}</label>
            <TextField
              variant="outlined"
              className='input-field'
              name='accountNumber'
              value={formDataForBank.accountNumber}
              onChange={handleBankFormData}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} className='name'>
            <label>{t("Home_Dashboard.bankDetails.swift_code")}</label>
            <TextField
              variant="outlined"
              className='input-field'
              name='swiftCode'
              value={formDataForBank.swiftCode}
              onChange={handleBankFormData}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} className='name'>
            <label>{t("Home_Dashboard.bankDetails.branch_num")}</label>
            <TextField
              variant="outlined"
              className='input-field'
              name='branchName'
              value={formDataForBank.branchName}
              onChange={handleBankFormData}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} className='btn-grid'>
          <Button className='btn-back btn' onClick={backToSettings}>
            <Typography variant='h6'>{t("Home_Dashboard.bankDetails.back")}</Typography>
          </Button>
          <Button className='btn' onClick={saveBankFormData}>
            <Typography variant='h6'>{t("Home_Dashboard.bankDetails.save_changes")}</Typography>
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}

export default EditBankDetails