import "../../../../src/mainseller.scss";
import { Divider } from '@mui/material'
import React, { useEffect } from 'react'
import InfoPoints from "../../../Components/FAQ_Info_Points/InfoPoints"
import { useTranslation } from "react-i18next";

const Faq = () => {
  const { t }: { t: (key: string) => string } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const faqSections = [
    {
      title: t("Home.faq.payment_terms"),
      detailText: t("Home.faq.payment_terms_dec")
    },
    {
      title: t("Home.faq.referral_fee"),
      detailText: t("Home.faq.referral_fee_desc")
    },
    {
      title: t("Home.faq.return_policy"),
      detailText: t("Home.faq.return_policy_dec")
    },
    {
      title: t("Home.faq.listing_process"),
      detailText: t("Home.faq.listing_process_desc")
    },
    {
      title: t("Home.faq.accountancy_process"),
      detailText: t("Home.faq.accountancy_process_desc")
    },
    {
      title: t("Home.faq.shipping_fee"),
      detailText: t("Home.faq.shipping_fee_desc")
    },
    {
      title: t("Home.faq.warranty"),
      detailText: t("Home.faq.warranty_desc")
    },
    {
      title: t("Home.faq.installation"),
      detailText: t("Home.faq.installation_desc")
    },
  ];

  return (
    <div className='faq-main center-div directionrtl'>
      <div className='main-head' >
        <div className='title'>
          <Divider className='title-line'/>
          <h1>{t("Home.faq.title")}</h1>
          <Divider className='title-line'/>
        </div>
      </div>
      <div className="faq-bottom-section">
        {/* <InfoPoints/> */}
        {faqSections.map((section, index) => (
          <div key={index} className="points-section">
            <InfoPoints  title={section.title} detailText={section.detailText}/>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Faq