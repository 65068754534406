import React from 'react'
import "./Footer.css"
import { Box, Grid, Link, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t }: { t: (key: string) => string } = useTranslation();

  return (
    <>
    <div className='footer-wrap directionrtl'>
    <div className='footer-top'>
        <Box sx={{width: '100%',marginTop:"15px"}}>
        <Grid container spacing={2} sx={{display: 'flex', justifyContent: 'space-between', padding: '15px 0px'}}>
          <Grid item xs={12} md={6} lg={6} className='foooter-link' sx={{display: 'flex', alignItems: 'center'}}>
             <Link href="#">{t("Home.footer.term_and_condition")}</Link>
             <Link href="#">{t("Home.footer.privacy_policy")}</Link>
             <Link href="#">{t("Home.footer.help_support")}</Link>
          </Grid>
          <Grid item xs={12} md={6} lg={6} className='support-text'>
            <Typography variant='h6'>{t("Home.footer.desc1")} <Link href="#">{t("Home.footer.desc2")}</Link></Typography>
          </Grid>
         
        </Grid>
        
        </Box>
       
    </div>
    <div className='footer-bottom'>
        <p>{t("Home.footer.all_right_reserved")}</p>
    </div>
    </div>
    </>
  )
}

export default Footer