import { Box, Button, Divider, Grid, List, ListItem, TextField, Typography } from '@mui/material'
import "../../../../src/mainseller.scss";
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const Advertise = () => {
    const { t }: { t: (key: string) => string } = useTranslation();
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phoneNumber: '',
        company: '',
        message: ''
    });

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleFormSubmit = (e: any) => {
        e.preventDefault();
        setFormData({
            name: '',
            email: '',
            phoneNumber: '',
            company: '',
            message: ''
        });
    }

    return (
        <>
            <div className='seller-acc-main advertise-main directionrtl'>
                <div className='main-head' >
                    <div className='title'>
                        <Divider className='title-line' />
                        <h1>{t("Home.advertise.title")}</h1>
                        <Divider className='title-line' />
                    </div>
                </div>
                <form onSubmit={handleFormSubmit}>
                    <Box sx={{
                        border: "1px solid #DEDCDC",
                        backgroundColor: "#FFFFFF"
                    }} className="main-box">
                        <List>
                            <ListItem>
                                <Typography className='title'>
                                {t("Home.advertise.details")}
                                </Typography>
                            </ListItem>
                            <Divider />
                            <Grid className='company-upper' container spacing={2} sx={{ padding: '30px' }}>
                                <Grid item xs={12}>
                                    <label>{t("Home.advertise.name")}</label>
                                    <TextField
                                        name='name'
                                        value={formData.name}
                                        id="outlined-basic"
                                        variant="outlined"
                                        onChange={handleChange}
                                        className='input-field' />
                                </Grid>
                                <Grid item xs={12}>
                                    <label>{t("Home.advertise.email")}</label>
                                    <TextField
                                        name='email'
                                        type='email'
                                        value={formData.email}
                                        id="outlined-basic"
                                        variant="outlined"
                                        onChange={handleChange}
                                        className='input-field' />
                                </Grid>
                                <Grid item xs={12} >
                                    <label>{t("Home.advertise.phone_no")}</label>
                                    <TextField
                                        name='phoneNumber'
                                        value={formData.phoneNumber}
                                        id="outlined-basic"
                                        variant="outlined"
                                        onChange={handleChange}
                                        className='input-field' />
                                </Grid>
                                <Grid item xs={12}>
                                    <label>{t("Home.advertise.company")}</label>
                                    <TextField
                                        name='company'
                                        value={formData.company}
                                        id="outlined-basic"
                                        variant="outlined"
                                        onChange={handleChange}
                                        className='input-field' />
                                </Grid>
                                <Grid item xs={12}>
                                    <label>{t("Home.advertise.details/message")}</label>
                                    <TextField
                                        name='message'
                                        value={formData.message}
                                        id="outlined-basic"
                                        variant="outlined"
                                        onChange={handleChange}
                                        className='input-field' />
                                </Grid>
                            </Grid>
                        </List>
                    </Box>
                    <Box sx={{ width: '100%', marginTop: '30px' }}>
                        <Button type='submit' className='create-btn'>{t("Home.advertise.submit")}</Button>
                    </Box>
                </form>
            </div>
        </>
    )
}

export default Advertise
