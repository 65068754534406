import { Box, Grid, Typography, Divider, TextField, Button } from '@mui/material'
import React, { useContext, useState } from 'react'
import "./Settings.scss"
import { useMutation } from '@apollo/client';
import { settingsMutation } from '../../../Operations/Mutations';
import { toast } from 'react-toastify';
import Toaster from '../../../Components/ToastMessage';
import { useNavigate } from 'react-router-dom';
import { CountryContext } from '../../..';
import { useTranslation } from 'react-i18next';

const EditName = () => {
    const [editedName, setEditedName] = useState({firstName : "", lastName: ""});
    const navigate = useNavigate()
    const loggedInToken = localStorage.getItem("loggedInCust");
    const { t }: { t: (key: string) => string } = useTranslation();
    const {store} = useContext(CountryContext);

    const [updateName] = useMutation(
      settingsMutation.UPDATE_PICKUP_MUTATION,
      {
        context: {
          headers: {
            Authorization: loggedInToken
              ? `Bearer ${JSON.parse(loggedInToken)}`
              : "",
            Connection: "keep-alive",
            store: store,
          },
        },
      }
    );

    const saveEditedName = async () => {
        try{
         await updateName({
            variables: {
              input : {
                companyInformation: [{
                  firstname: editedName.firstName,
                  lastname: editedName.lastName,
              }]
              }
            }    
          });
          toast.success(t("Home_Dashboard.editName.sucess_toast"), {
            position: toast.POSITION.TOP_RIGHT
        });
          setEditedName({firstName : "", lastName: ""})
        }catch(error){
          toast.error(t("Home_Dashboard.editName.error_toast"), {
            position: toast.POSITION.TOP_RIGHT
        });
        }
      }

      const backToSettings = () => {
        navigate("/dashboard/settings")
      }
      
  return (
    <Box sx={{ width: '100%' }} className="edit-name-box">
      <Toaster/>
        <Grid container spacing={2} className="change-name">
                <Grid item xs={12} sm={12} md={12} className='settings-header'>
                  <Typography variant='h5'>{t("Home_Dashboard.editName.title")}</Typography>
                </Grid>
                <Divider className='divider' />
                <Grid container spacing={2} xs={12} sm={12} md={12} lg={9} className='form-details'>
                  <Grid item xs={12} sm={12} md={12} className='title'>
                    <Typography variant='h6'>If you {t("Home_Dashboard.editName.desc")}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} className='name'>
                    <label>{t("Home_Dashboard.editName.f_name")}:</label>
                    <TextField
                      variant="outlined"
                      className='input-field'
                      name='firstName'
                      placeholder={t("Home_Dashboard.editName.f_name_placeholder")}
                      value={editedName.firstName}
                      onChange={(e) => setEditedName({ ...editedName, firstName: e.target.value })}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} className='name'>
                    <label>{t("Home_Dashboard.editName.l_name")}:</label>
                    <TextField
                      variant="outlined"
                      className='input-field'
                      name='lastName'
                      placeholder={t("Home_Dashboard.editName.l_name_placeholder")}
                      value={editedName.lastName}
                      onChange={(e) => setEditedName({ ...editedName, lastName: e.target.value })}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={8} className='btn-grid'>
                  <Button className='btn btn-back' onClick={backToSettings}><Typography variant='h6'>{t("Home_Dashboard.editName.back")}</Typography></Button>
                  <Button className='btn' onClick={saveEditedName}><Typography variant='h6'>{t("Home_Dashboard.editName.save_changes")}</Typography></Button>
                </Grid>
              </Grid>
    </Box>
  )
}

export default EditName