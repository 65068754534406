import { Typography, Box, List, Grid, TextField, Button, Alert, AlertTitle } from '@mui/material'
import React, { useContext, useState } from 'react'
import "../../../../src/mainseller.scss";
import { useMutation } from '@apollo/client';
import * as mutations from "../../../Operations/Mutations";
import { CountryContext } from '../../..';
import Toaster from '../../../Components/ToastMessage';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const generateRandomCaptcha = (): string => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let captcha = '';
    for (let i = 0; i < 6; i++) {
      captcha += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return captcha;
  };

const ForgotPwd = () => {
    const [captcha, setCaptcha] = useState(generateRandomCaptcha());
    const [userInput, setUserInput] = useState('');
    const [forgotPwd, setForgotPwd] = useState({ email: ""})
    const [resError, setResError] = useState("");
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const loggedInToken = localStorage.getItem("loggedInCust");
    const {store} = useContext(CountryContext);
    const { t }: { t: (key: string) => string } = useTranslation();

    let [passwordResetRequest] = useMutation(
        mutations.forgotPwdMutation.FORGOTPWD_MUTATION,
        {
            context: {
                headers: {
                    Authorization: loggedInToken
                  ? `Bearer ${JSON.parse(loggedInToken)}`
                  : "",
                    Connection: "keep-alive",
                    store: store,
                },
            },
        }
    );
    
    const handleRefresh = () => {
        setCaptcha(generateRandomCaptcha());
        setUserInput('');
      };

      const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUserInput(e.target.value);
      };

      //eslint-disable-next-line
    //   const handleSubmit = () => {
    //     if (userInput === captcha) {
    //       alert('Captcha Matched!');
    //     } else {
    //       alert('Incorrect Captcha. Please try again.');
    //     }
    //   };

    const handleForgotPwdRequest = async (event: any) => {
        event.preventDefault();
        if (userInput !== captcha) {
            toast.error(t("Home.forget_pwd.captcha_err"));
            return;
          }
        const data = forgotPwd;
        try {
            toast.info(t("Home.forget_pwd.process_req"), {
                position: toast.POSITION.TOP_RIGHT
            });
           await passwordResetRequest({
                variables: data,
            });
            setForgotPwd({ email: ""});
            setUserInput('');
            
            toast.success(t("Home.forget_pwd.pwd_reset_msg"), {
                position: toast.POSITION.TOP_RIGHT
            });
            // navigate(`/dashboard`);
        } catch (error: any) {
            if (error) {
                setResError(t("Home.forget_pwd.invalid_cred"));
                setShowErrorAlert(true);
                toast.error(t("Home.forget_pwd.invalid_cred"), {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            setForgotPwd({ email: ""});
            setUserInput('');
        }
    }

    return (
        <>
            <div className='login-main directionrtl'>
                <div className='seller-acc-main forgot-block'>
                    {<Toaster/>}
                    <Typography variant='h1'>{t("Home.forget_pwd.title")}</Typography>
                    {showErrorAlert && (
                      <Alert severity="error">
                        <AlertTitle>Error</AlertTitle>
                        {resError}
                      </Alert>
                    )}
                    <form onSubmit={handleForgotPwdRequest} >
                        <Box sx={{
                            border: "1px solid #DEDCDC",
                            backgroundColor: "#FFFFFF"
                        }} className="main-box">
                            <List>
                                <Grid className='company-upper' container spacing={2} sx={{ padding: '30px' }}>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <label>{t("Home.forget_pwd.email")}</label>
                                        <TextField
                                            name='email'
                                            variant="outlined"
                                            className='input-field'
                                            type='email'
                                            value={forgotPwd.email}
                                            onChange={(e: any)=>setForgotPwd({email: e.target.value})}
                                            autoComplete='on'
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <label>{t("Home.forget_pwd.letter_desc")}</label>
                                        <TextField
                                            name='letters'
                                            variant="outlined"
                                            className='input-field'
                                            value={userInput} 
                                            onChange={handleChange}
                                            required 
                                        />
                                    </Grid>
                                    <Grid item xs={12} sx={{ maxWidth: '100% !important' }}>
                                        <Box className='capture-block'>
                                            <Box className="capture-img" >
                                            {captcha}
                                            </Box>
                                            <Box className="create-ac-btn" onClick={handleRefresh}>
                                            {t("Home.forget_pwd.reload_captcha")}
                                            </Box>
                                        </Box>
                                        {/* <Button onClick={handleSubmit}>Check</Button> */}
                                    </Grid>
                                    <Grid item xs={12} sx={{ maxWidth: '100% !important' }}>
                                        <Box sx={{ width: '100%', marginTop: '100px' }}>
                                            <Button className='create-btn' type='submit'>
                                            {t("Home.forget_pwd.forgot_pwd")}
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </List>
                        </Box>
                    </form>
                </div>
            </div>
        </>
    )
}

export default ForgotPwd